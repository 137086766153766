import React, {useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { MultiTextInput } from '../input/MultiTextInput';
import Form from 'react-bootstrap/Form';

export function DateCellEditor (data) {
    return data.value ? (new Date(data.value)).toLocaleDateString() : ''; //.format('MM/DD/YYYY')
}

export const RequiredCellEditor2 = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value;
            },
        };
    });

    const onChange = (event) => {
        const newValue = event.target.value;
        if(newValue)
            setValue(newValue);
    }

    return (
        <Form.Control 
            placeholder="Name"
            required
            type="text"
            value={value || ""}
            ref={ref}
            onChange={onChange}
        />
    )
});



export function MultiTextCellEditor({ value, onValueChange, options, eventKey, rowIndex, column }, ref) {
    const updateValue = (val) => {
        onValueChange(val === '' ? null : val);
    };

    useEffect(() => {

        //refInput.current.focus();
    }, []);

    const refInput = useRef(null);

    return (
        <div className="ag-cell-edit-wrapper">
            <MultiTextInput
                required
                setValue={onValueChange}
                value={value}
                //ref={refInput}
                className="ag-cell-editor"
                placeholder="Select function(s)..."
                creatable={false}
                options={options}
            />
        </div>
    );
}

function isEmpty(val) {
    return val === undefined || val === null || val === '';
}
export class RequiredCellEditor {
    constructor() { }
    init(params) {
        //console.log(params);
        if(params.regex)
            this.regex = params.regex;
        this.eGui = document.createElement('div');
        this.eGui.classList.add('required-editor');
        this.eGui.classList.add('ag-cell-edit-wrapper');
        this.eGui.innerHTML = `<input required value="${params.value}" class="form-control ag-cell-editor">`; // <div class="invalid-feedback">Please enter a value</div>`;
        this.eInput = this.eGui.querySelector('input');
        this.eInput.addEventListener('input', this.inputChanged.bind(this));
    }
    inputChanged(event) {
        let val = event.target.value;
        if (isEmpty(val)) {
            this.setValid(false);
        } else {
            if(this.regex && !this.regex.test(val))
                this.setValid(false);
            else
                this.setValid(true);
        }
    }
    setValid(isValid) {
        this.isValid = isValid;
        if(isValid)
            this.eInput.classList.remove("is-invalid");
        else
            this.eInput.classList.add("is-invalid");
    }
    isCancelAfterEnd() {
        return !this.isValid;
    }
    getValue() {
        return this.eInput.value;
    }
    getGui() {
        return this.eGui;
    }
    destroy() {
        this.eInput.removeEventListener('input', this.inputChanged);
    }
}

  
export const AgGridMultiSelectEditor = React.forwardRef((props, _ref) => {
    const [value, setValue] = useState(props.value);
    const refInput = useRef(null);

    useEffect(() => {
        // focus on input
        refInput.current?.focus();
    }, []);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(_ref, () => {
        return {
            getValue: () => {
                return value.map(a => a.label);
            },
            isPopup: () => {
                return false;
            },
        };
    });

    return (
        <MultiTextInput
            ref={refInput}
            required
            options={props.options}
            setValue={setValue}
            value={value}
            placeholder="Select function(s)..."
            creatable={false}
        />
        /*<div className="ag-cell-edit-wrapper">
             <div className="ag-cell-editor"> 
                
             </div> 
             <MultiSelect
                ref={refInput}
                className="ag-cell-editor"
                data={props.options.map((option) => ({ label: option, value: option }))}
                value={value}
                onChange={setValue}
                searchable
            /> 
        </div>*/
    );
});




