export const surveyResultData = [
    {
      "questionId": 1,
      "questionGroup": "Strategy",
      "average": 3,
      "alignmentRank": 3.078947368
    },
    {
      "questionId": 2,
      "questionGroup": "Strategy",
      "average": 3.263157895,
      "alignmentRank": 2.763157895
    },
    {
      "questionId": 3,
      "questionGroup": "Strategy",
      "average": 3.131578947,
      "alignmentRank": 3.131578947
    },
    {
      "questionId": 4,
      "questionGroup": "Strategy",
      "average": 3.263157895,
      "alignmentRank": 3
    },
    {
      "questionId": 5,
      "questionGroup": "Strategy",
      "average": 3.052631579,
      "alignmentRank": 2.842105263
    },
    {
      "questionId": 6,
      "questionGroup": "Strategy",
      "average": 3.105263158,
      "alignmentRank": 2.842105263
    },
    {
      "questionId": 7,
      "questionGroup": "Strategy",
      "average": 2.947368421,
      "alignmentRank": 3.236842105
    },
    {
      "questionId": 8,
      "questionGroup": "Strategy",
      "average": 2.578947368,
      "alignmentRank": 3.157894737
    },
    {
      "questionId": 10,
      "questionGroup": "Work Process",
      "average": 2.578947368,
      "alignmentRank": 2.657894737
    },
    {
      "questionId": 11,
      "questionGroup": "Work Process",
      "average": 3.026315789,
      "alignmentRank": 2.973684211
    },
    {
      "questionId": 12,
      "questionGroup": "Work Process",
      "average": 3.184210526,
      "alignmentRank": 2.947368421
    },
    {
      "questionId": 13,
      "questionGroup": "Work Process",
      "average": 2.789473684,
      "alignmentRank": 3.052631579
    },
    {
      "questionId": 14,
      "questionGroup": "Work Process",
      "average": 3.526315789,
      "alignmentRank": 2.973684211
    },
    {
      "questionId": 15,
      "questionGroup": "Work Process",
      "average": 2.631578947,
      "alignmentRank": 2.894736842
    },
    {
      "questionId": 16,
      "questionGroup": "Work Process",
      "average": 2.526315789,
      "alignmentRank": 2.736842105
    },
    {
      "questionId": 17,
      "questionGroup": "Work Process",
      "average": 2.842105263,
      "alignmentRank": 2.842105263
    },
    {
      "questionId": 18,
      "questionGroup": "Work Process",
      "average": 2.789473684,
      "alignmentRank": 3.289473684
    },
    {
      "questionId": 20,
      "questionGroup": "Structure & Governance",
      "average": 3.526315789,
      "alignmentRank": 3.026315789
    },
    {
      "questionId": 21,
      "questionGroup": "Structure & Governance",
      "average": 2.763157895,
      "alignmentRank": 3.131578947
    },
    {
      "questionId": 22,
      "questionGroup": "Structure & Governance",
      "average": 2.947368421,
      "alignmentRank": 2.894736842
    },
    {
      "questionId": 23,
      "questionGroup": "Structure & Governance",
      "average": 3.5,
      "alignmentRank": 2.973684211
    },
    {
      "questionId": 24,
      "questionGroup": "Structure & Governance",
      "average": 2.421052632,
      "alignmentRank": 2.789473684
    },
    {
      "questionId": 25,
      "questionGroup": "Structure & Governance",
      "average": 2.894736842,
      "alignmentRank": 3.157894737
    },
    {
      "questionId": 26,
      "questionGroup": "Structure & Governance",
      "average": 2.657894737,
      "alignmentRank": 2.710526316
    },
    {
      "questionId": 27,
      "questionGroup": "Structure & Governance",
      "average": 2.763157895,
      "alignmentRank": 2.342105263
    },
    {
      "questionId": 28,
      "questionGroup": "Structure & Governance",
      "average": 2.605263158,
      "alignmentRank": 2.789473684
    },
    {
      "questionId": 29,
      "questionGroup": "Structure & Governance",
      "average": 2.710526316,
      "alignmentRank": 3.026315789
    },
    {
      "questionId": 31,
      "questionGroup": "Information & Metrics",
      "average": 2.763157895,
      "alignmentRank": 2.842105263
    },
    {
      "questionId": 32,
      "questionGroup": "Information & Metrics",
      "average": 2.894736842,
      "alignmentRank": 3.157894737
    },
    {
      "questionId": 33,
      "questionGroup": "Information & Metrics",
      "average": 2.947368421,
      "alignmentRank": 2.815789474
    },
    {
      "questionId": 34,
      "questionGroup": "Information & Metrics",
      "average": 2.526315789,
      "alignmentRank": 2.710526316
    },
    {
      "questionId": 35,
      "questionGroup": "Information & Metrics",
      "average": 2.868421053,
      "alignmentRank": 2.842105263
    },
    {
      "questionId": 36,
      "questionGroup": "Information & Metrics",
      "average": 3.473684211,
      "alignmentRank": 2.5
    },
    {
      "questionId": 38,
      "questionGroup": "People & Rewards",
      "average": 3,
      "alignmentRank": 3.078947368
    },
    {
      "questionId": 39,
      "questionGroup": "People & Rewards",
      "average": 2.973684211,
      "alignmentRank": 2.921052632
    },
    {
      "questionId": 40,
      "questionGroup": "People & Rewards",
      "average": 2.605263158,
      "alignmentRank": 3.078947368
    },
    {
      "questionId": 41,
      "questionGroup": "People & Rewards",
      "average": 2.947368421,
      "alignmentRank": 2.947368421
    },
    {
      "questionId": 42,
      "questionGroup": "People & Rewards",
      "average": 2.236842105,
      "alignmentRank": 2.552631579
    },
    {
      "questionId": 43,
      "questionGroup": "People & Rewards",
      "average": 2.736842105,
      "alignmentRank": 3.105263158
    },
    {
      "questionId": 45,
      "questionGroup": "Continuous Improvement",
      "average": 2.631578947,
      "alignmentRank": 2.842105263
    },
    {
      "questionId": 46,
      "questionGroup": "Continuous Improvement",
      "average": 2.815789474,
      "alignmentRank": 2.815789474
    },
    {
      "questionId": 47,
      "questionGroup": "Continuous Improvement",
      "average": 2.578947368,
      "alignmentRank": 2.815789474
    },
    {
      "questionId": 48,
      "questionGroup": "Continuous Improvement",
      "average": 2.973684211,
      "alignmentRank": 2.684210526
    },
    {
      "questionId": 49,
      "questionGroup": "Continuous Improvement",
      "average": 2.973684211,
      "alignmentRank": 2.710526316
    },
    {
      "questionId": 51,
      "questionGroup": "Leadership & Culture",
      "average": 3.263157895,
      "alignmentRank": 3.315789474
    },
    {
      "questionId": 52,
      "questionGroup": "Leadership & Culture",
      "average": 3.578947368,
      "alignmentRank": 2.947368421
    },
    {
      "questionId": 53,
      "questionGroup": "Leadership & Culture",
      "average": 3.157894737,
      "alignmentRank": 3
    },
    {
      "questionId": 54,
      "questionGroup": "Leadership & Culture",
      "average": 3.105263158,
      "alignmentRank": 3.342105263
    },
    {
      "questionId": 55,
      "questionGroup": "Leadership & Culture",
      "average": 2.815789474,
      "alignmentRank": 3.131578947
    },
    {
      "questionId": 56,
      "questionGroup": "Leadership & Culture",
      "average": 2.947368421,
      "alignmentRank": 3.236842105
    },
    {
      "questionId": 58,
      "questionGroup": "Organization & Alignment",
      "average": 3.315789474,
      "alignmentRank": 3.105263158
    },
    {
      "questionId": 59,
      "questionGroup": "Organization & Alignment",
      "average": 3.289473684,
      "alignmentRank": 3.105263158
    },
    {
      "questionId": 60,
      "questionGroup": "Organization & Alignment",
      "average": 2.736842105,
      "alignmentRank": 3.131578947
    },
    {
      "questionId": 61,
      "questionGroup": "Organization & Alignment",
      "average": 3.105263158,
      "alignmentRank": 2.815789474
    },
    {
      "questionId": 62,
      "questionGroup": "Organization & Alignment",
      "average": 2.973684211,
      "alignmentRank": 3.394736842
    },
    {
      "questionId": 63,
      "questionGroup": "Organization & Alignment",
      "average": 2.710526316,
      "alignmentRank": 2.842105263
    },
    {
      "questionId": 65,
      "questionGroup": "Guiding Transformation",
      "average": 2.815789474,
      "alignmentRank": 3.131578947
    },
    {
      "questionId": 66,
      "questionGroup": "Guiding Transformation",
      "average": 2.657894737,
      "alignmentRank": 3.078947368
    },
    {
      "questionId": 67,
      "questionGroup": "Guiding Transformation",
      "average": 3.131578947,
      "alignmentRank": 3.026315789
    },
    {
      "questionId": 68,
      "questionGroup": "Guiding Transformation",
      "average": 3.052631579,
      "alignmentRank": 3.131578947
    },
    {
      "questionId": 69,
      "questionGroup": "Guiding Transformation",
      "average": 2.842105263,
      "alignmentRank": 2.947368421
    },
    {
      "questionId": 70,
      "questionGroup": "Guiding Transformation",
      "average": 2.684210526,
      "alignmentRank": 2.868421053
    },
    {
      "questionId": 71,
      "questionGroup": "Guiding Transformation",
      "average": 3.026315789,
      "alignmentRank": 2.868421053
    },
    {
      "questionId": 72,
      "questionGroup": "Guiding Transformation",
      "average": 2.657894737,
      "alignmentRank": 3.105263158
    },
    {
      "questionId": 73,
      "questionGroup": "Guiding Transformation",
      "average": 2.947368421,
      "alignmentRank": 2.947368421
    },
    {
      "questionId": 74,
      "questionGroup": "Guiding Transformation",
      "average": 3.315789474,
      "alignmentRank": 2.921052632
    }
   ];