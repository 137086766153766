import React, { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import Alert from 'react-bootstrap/Alert';
import PlainLight from "survey-core/themes/plain-light.js";
import "survey-core/defaultV2.css";
import moment from 'moment';

import { json } from "../data/survey_json.js";

const storageItemKey = "alignOrg-survey";
const survey = new Model(json);
survey.applyTheme(PlainLight);


function setSurveyDataFromSurveyResult(surveyResult) {
  let answerData = {}
  surveyResult.questionResults.forEach(a => answerData[a.questionName] = a.score ? a.score : a.comment);
  survey.data = answerData;
  if (surveyResult.pageNumber) {
    survey.currentPageNo = surveyResult.pageNumber;
  }
}

//?clientId=664eb6124d2de2bcd6c52268&surveyId=664eb5ea4d2de2bcd6c52267&employeeId=664eb63e4d2de2bcd6c52269
export function SurveyPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const surveyId = searchParams.get("surveyId");
  const clientId = searchParams.get("clientId");
  const employeeId = searchParams.get("employeeId");
  const initialSurveyResult = { surveyId, clientId, employeeId, isComplete: false, pageNumber: 0, questionResults: [] };
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientSurvey, setClientSurvey] = useState(null);
  const [surveyResult, setSurveyResult] = useState(initialSurveyResult);



  const getSurvey = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}Clients/${clientId}/Surveys/${surveyId}`;
      const response = await fetch(url);

      if (response.ok) {
        const surveyData = await response.json();
        setClientSurvey(surveyData);
        //Check if survey is closed
        if(surveyData.status === "Closed" || moment().isAfter(surveyData.endDate))
          setError("This survey is closed");  //survey.SurveyError("This survey is closed");  
      }
      else
        setError("Survey not found");

    } catch (error) {
      setError(error.message);
    }
  }

  const getSurveyResult = async () => {    
    try {
      // Check local storage for survey data
      let surveyResultData = window.localStorage.getItem(storageItemKey) || null;
      if (surveyResultData) {
        surveyResultData = JSON.parse(surveyResultData);
        //SurveyResult from local storage is for a different employee
        if (surveyResultData.clientId !== clientId || surveyResultData.employeeId !== employeeId || surveyResultData.surveyId !== surveyId) {
          window.localStorage.setItem(storageItemKey, "");
          surveyResultData = null;
        }
      }

      // No data from local storage so check db for survey data
      if (!surveyResultData) {
        const url = `${process.env.REACT_APP_API_URL}SurveyResults/query?surveyId=${surveyId}&clientId=${clientId}&employeeId=${employeeId}`;
        const response = await fetch(url);

        if (response.ok) {
          surveyResultData = await response.json();
          window.localStorage.setItem(storageItemKey, JSON.stringify(surveyResultData));
        }
      }

      //First time the user has opened the survey
      if (!surveyResultData) { 
        window.localStorage.setItem(storageItemKey, JSON.stringify(surveyResult));
        const status = `Page ${survey.currentPageNo + 1}: ${survey.currentPage.name}`;
        updateEmployeeStatus(status);
      }

      // User has answered at least one question
      if (surveyResultData && surveyResultData.questionResults.length > 0) {
        setSurveyDataFromSurveyResult(surveyResultData);
        setSurveyResult(surveyResultData);
      }

      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    const surveyId = searchParams.get("surveyId");
    const clientId = searchParams.get("clientId");
    const employeeId = searchParams.get("employeeId");
    if(surveyId && clientId && employeeId) {
      //getSurveyQuestions();
      getSurvey();
      if (surveyResult.questionResults.length === 0) {
        getSurveyResult();
      }
    }
    else {
      setError("Invalid Survey");  //survey.SurveyError("Invalid Survey");  
      setLoading(false);
    }
  }, [searchParams]);

  function onValueChanged(survey, options) {
    surveyResult.pageNumber = survey.currentPageNo;
    const currentPage = survey.currentPage;
    const question = options.question;
    const questionId = parseInt(options.name.substring(1));
    const questionType = question.getType();
    let questionResult = surveyResult.questionResults.find(a => a.questionId === questionId);

    if (!questionResult) {
      questionResult = {
        questionId: questionId,
        questionGroup: currentPage.name,
        questionType: questionType,
        questionName: options.name,
        score: questionType === "rating" ? options.value : undefined,
        comment: questionType === "comment" ? options.value : undefined,
      };
      surveyResult.questionResults.push(questionResult);
    }
    else {
      questionResult.questionId = questionId;
      questionResult.questionGroup = currentPage.name;
      questionResult.questionType = questionType;
      questionResult.questionName = options.name;
      questionResult.score = questionType === "rating" ? options.value : undefined;
      questionResult.comment = questionType === "comment" ? options.value : undefined;
    }

    window.localStorage.setItem(storageItemKey, JSON.stringify(surveyResult));
    //saveSurveyResults();
  }

  function onCurrentPageChanged(survey, options) {
    surveyResult.pageNumber = survey.currentPageNo;
    window.localStorage.setItem(storageItemKey, JSON.stringify(surveyResult));
    saveSurveyResults();
    const status = `Page ${survey.currentPageNo + 1}: ${options.newCurrentPage.name}`;
    updateEmployeeStatus(status);
  }

  function onComplete(survey) {
    window.localStorage.setItem(storageItemKey, "");
    surveyResult.isComplete = true;
    saveSurveyResults();
    updateEmployeeStatus("Completed");
  }

  const saveSurveyResults = async () => {
    try {
      const url = surveyResult.id ? `${process.env.REACT_APP_API_URL}SurveyResults/${surveyResult.id}` : `${process.env.REACT_APP_API_URL}SurveyResults`;
      const method = surveyResult.id ? "PUT" : "POST";
      var params = {
        method,
        body: JSON.stringify(surveyResult),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        }
      };
      const response = await fetch(url, params);
      if (!response.ok) {
        throw new Error('Could not save survey data.');
      }
      if (response.status === 201) { //created
        const data = await response.json();
        setSurveyResult(data);
        window.localStorage.setItem(storageItemKey, JSON.stringify(surveyResult));
      }
      //setLoading(false);
    } catch (error) {
      setError(error.message);
      //setLoading(false);
    }
  }

  const updateEmployeeStatus = async (status) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}Clients/${clientId}/Employees/${employeeId}/SurveyStatus/${status}`;
      const method = "PUT";
      var params = {
        method,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        }
      };
      const response = await fetch(url, params);
      if (!response.ok) {
        throw new Error('Could not update employee status.');
      }
    } catch (error) {
      setError(error.message);
    }
  }

  return (
    <div className="container">
      {
        loading ? (
          <div className="h-100 d-flex justify-content-center align-items-center">
            <span className="spinner-border text-primary" style={{width: "4rem", height: "4rem"}} role="status" />
          </div>
        ) : error ? (
          <Alert key="danger" variant="danger">Error: {error}</Alert>
        ) : (
          <Survey
            model={survey}
            onComplete={onComplete}
            onValueChanged={onValueChanged}
            onCurrentPageChanged={onCurrentPageChanged}
          />
        )
      }
    </div>
  );
}
