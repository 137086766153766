import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NavBar } from "./pages/NavBar";
import { HomePage } from "./pages/Home";
import { ClientPage } from "./pages/Client";
import { SurveyPage } from "./pages/Survey";
import { ResultsPage } from "./pages/Results";
import './styles/App.css';
import "bootstrap/dist/css/bootstrap.css";
import { ProtectedComponent } from './components/login/ProtectedComponent';

const App = () => {
    return (
      <Router>
            <NavBar />
            <div className="app-content">
              <Routes>
                <Route exact path="/"
                  element={ 
                    <ProtectedComponent>
                      <HomePage />
                    </ProtectedComponent> 
                  } 
                />
                <Route path="/clients/:id" 
                  element={ 
                    <ProtectedComponent>
                      <ClientPage />
                    </ProtectedComponent> 
                  } 
                />
                <Route path="/results/:clientId/:surveyId" 
                  element={
                    <ProtectedComponent>
                      <ResultsPage />
                    </ProtectedComponent> 
                  } 
                />
                
                <Route path="/survey" element={<SurveyPage />} />
              </Routes>
            </div>
      </Router>
    );
}

export default App;
