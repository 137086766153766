import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';


const round = (value) => Math.round(value * 10) / 10;

export default function OverallMetrics(props) {
    const allQuestionMetrics = props.questionMetrics;
    const questionGroupMetrics = props.questionGroupMetrics;
    const questionMetrics = props.isLeftSide ? allQuestionMetrics.filter(a => a.questionId > 37).reverse() : allQuestionMetrics;
    const questionGroups = [...new Set(questionMetrics.map(a => a.questionGroup))];
    const getClassFromScore = props.getClassFromScore;
    const getClassFromAlignment = props.getClassFromAlignment;
    const onQuestionClick = props.onQuestionClick;
    const questions = props.questions;
    const enabledLevels = props.enabledLevels;
    const totalColumns =  props.showDegreeOfAlignment ? enabledLevels.length * 2 : enabledLevels.length;
    const headerColSpan = totalColumns > 1 ? 2 : 2 + enabledLevels.length;
    
    return (
        <div>
            <Table bordered hover size="sm" className="table-xs mb-0">
                <tbody>
                    { questionGroups.map((questionGroup, groupIndex) => {
                        const groupQuestions = questionMetrics.filter(a => a.questionGroup === questionGroup);
                        const average = enabledLevels.length === 1 ? round(questionGroupMetrics.find(a => a.questionGroup === questionGroup).metricScores.find(a => a.name === enabledLevels[0]).average)
                            : round(questionGroupMetrics.find(a => a.questionGroup === questionGroup).metricScores.find(a => a.name === "All").average);
                        return (
                            <React.Fragment key={groupIndex}>
                                <tr className="tr-top">
                                    <th colSpan="2" className="clickable text-center" onClick={() => props.onLabelClick(questionGroup)}>{questionGroup}<span style={{marginLeft: "5px", padding: "3px"}} className={`h-100 ${getClassFromScore(average)}`}>{average.toFixed(1)}</span></th>
                                    { enabledLevels.map((level, index) => {
                                        return <th key={index} colSpan={props.showDegreeOfAlignment ? 2 : 1} className="text-center clickable" style={{fontSize: "11px"}} onClick={props.toggleDegreeOfAlignment}>{level}</th>
                                    })}
                                </tr>
                                { groupQuestions.map((questionMetric, idx) => {
                                    const isLast = idx === (groupQuestions.length - 1);
                                    const questionIndex = allQuestionMetrics.findIndex(a => a.questionId === questionMetric.questionId);
                                    return (
                                        <tr key={questionMetric.questionId} className={`clickable ${isLast ? "tr-bottom" : "tr-mid"}`} onClick={() => onQuestionClick(questionIndex)}>
                                            {/* { showGroupLabel && <td className="align-top rotate-90" rowSpan={questionGroupCount}><div className="text-center-middle align-top"><span>{questionMetric.questionGroup}</span></div></td> } */}
                                            <td className="text-center-middle">{questionMetric.questionName}</td>
                                            <td className="text-wrap">{questions[questionIndex].shortTitle}</td>

                                            { enabledLevels.map((level, index) => {
                                                const metricScore = questionMetric.metricScores.find(a => a.name === level);
                                                const average = round(metricScore.average);
                                                const alignmentIndex = round(metricScore.alignmentIndex);
                                                return (
                                                    <React.Fragment key={index}>
                                                        { props.showAverageScore && <td className={`text-center-middle ${getClassFromScore(average)}`} >{average.toFixed(1)}</td> }
                                                        { props.showDegreeOfAlignment && <td className={`text-center-middle ${getClassFromAlignment(alignmentIndex)}`} /> }
                                                        {/* { props.showDegreeOfAlignment && <td className={`text-center-middle fg-white ${getClassFromAlignment(alignmentIndex)}`}>{alignmentIndex}</td> } */}
                                                        {/* { props.showDegreeOfAlignment && <td className={`text-center-middle fg-white ${getClassFromAlignment(alignmentIndex)}`}></td> } */}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
}