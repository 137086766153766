import React from 'react';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from "./authConfig.js";
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';

/**
* MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
* For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
*/
const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback((event) => {
        // Handle ERROR ServerError: access_denied: AADB2C90091: The user has cancelled entering self-asserted information
        if(event.error && event.error.message.indexOf('AADB2C90091') > -1) {
            msalInstance.loginRedirect();
        }
        if (
            (event.eventType === EventType.LOGIN_SUCCESS ||
                event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                event.eventType === EventType.SSO_SILENT_SUCCESS) &&
            event.payload.account
        ) {
            msalInstance.setActiveAccount(event.payload.account);
        }
    });

    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </React.StrictMode>
    );
});
