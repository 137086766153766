import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Alert, Button, Modal, Form, Tabs, Tab, Table } from 'react-bootstrap';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { SurveyLinkAlert } from '../components/SurveyLinkAlert';
import { EmployeeGrid } from '../components/EmployeeGrid';
import { SurveyGrid } from '../components/SurveyGrid';
import CreatableSelect from 'react-select/creatable';
import { MultiTextInput } from '../components/input/MultiTextInput';
import { ConfirmDialog } from '../components/controls/ConfirmDialog';
import Papa from 'papaparse';
import moment from 'moment';

const levelOptions = [ "L0", "L1", "L2", "L3+" ];

const functionOptions = [
    { value: "Commercial", label: "Commercial" },
    { value: "Customer Service", label: "Customer Service" },
    { value: "Enterprise", label: "Enterprise" },
    { value: "Finance", label: "Finance" },
    { value: "Human Resources", label: "Human Resources" },
    { value: "Information Technology", label: "Information Technology" },
    { value: "Legal", label: "Legal" },
    { value: "Marketing", label: "Marketing" },
    { value: "Operations", label: "Operations" },
    { value: "Procurement", label: "Procurement" },
    { value: "Research and Development", label: "Research and Development" },
    { value: "Sales", label: "Sales" },
];

const columnHeaders = [ "name", "email", "title", "managementLevel", "functions" ];

function dateCellRenderer(data) {
    return data.value ? (new Date(data.value)).toLocaleDateString() : ""; //.format('MM/DD/YYYY')
}

function isValidEmail(email) {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
}

export function ClientPage() {
    const { id } = useParams();
    // const { state } = useContext(AppContext);
    // const client = state.clients[match.params.id];

    const [client, setClient] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!client)
            getClient();
    }, [client]); //[id]);

    const getClient = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}Clients/${id}`);
            if (!response.ok) {
                throw new Error('Could not retrieve client.');
            }
            const data = await response.json();
            setClient(data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }

    //Employee
    const [alertShow, setAlertShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("primary");
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [title, setTitle] = useState("");
    const [managementLevel, setManagementLevel] = useState("");
    const [functions, setFunctions] = useState([]);
    const [employeeId, setEmployeeId] = useState();
    const [showImportEmployees, setShowImportEmployees] = useState(false);
    const [showImportError, setShowImportError] = useState(false);
    const [importError, setImportError] = useState({});
    const [parsedData, setParsedData] = useState();
    const [showSendSurveyConfirmation, setShowSendSurveyConfirmation] = useState(false);
    const [showSendSurveyToAllConfirmation, setShowSendSurveyToAllConfirmation] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setFunctions([]);
    }

    const handleCloseImportEmployees = () => {
        setImportError({});
        setShowImportError(false);
        setParsedData();
        setShowImportEmployees(false);
    };
 
    const showAlert = (variant, message) => {
        setAlertVariant(variant);
        setAlertMessage(message);
        setAlertShow(true);
    }

    const displayImportError = (header, message) => {
        setShowImportError(true);
        setImportError({ header, message });
    }

    const handleSave = async (event) => {
        event.preventDefault();
        setShow(false);
        
        try {
            //check for existing client
            if(client.employees.find(a => a.email === email))
                throw new Error(`Could not add employee. ${email} already exists.`);

            const employee = JSON.stringify({
                clientId: client.id,
                name,
                email,
                title,
                managementLevel,
                functions: functions.map(a => a.label),
                currentSurveyStatus: "Not Started",
            });
            var params = {
                method: "POST",
                body: employee,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                }
            };
            const response = await fetch(`${process.env.REACT_APP_API_URL}Clients/${id}/Employees`, params);
            if (response.ok) {
                const data = await response.json();
                setClient(data);
                showAlert("success", `Successfully added ${name}`);
            }
            else {
                showAlert("danger", "Could not add employee");
            }
        } catch (error) {
            showAlert("danger", error.message);
        } finally {
            setFunctions([]);
            setLoading(false);
        }
    }

    const handleFileChange = (event) => {
        if(showImportError)
            setImportError(false);

        if(event.target.files.length) {
            const inputFile = event.target.files[0];

            const fileExtension = inputFile?.type.split("/")[1];
            if(fileExtension !== "csv") {
                displayImportError("Invalid File", "Please select a csv file");
                return;
            }

            //read file
            const reader = new FileReader();

            reader.onload = async ({ target }) => {
                //parse data
                const result = Papa.parse(target.result, {
                    header: true,
                    skipEmptyLines: true,
                    transformHeader: (header) => {
                        let newHeader = header.replace(/\s/g, '');
                        return newHeader.charAt(0).toLowerCase() + newHeader.slice(1);
                    }
                });
                const parsedData = result?.data;
                const headers = Object.keys(parsedData[0]);
                
                if(!headers.every(a => columnHeaders.includes(a))) {
                    displayImportError("Invalid Columns In File", `Columns must include ${columnHeaders.join(", ")}`);
                    return;
                }

                let employees = []; 
                let errors = [];

                for(let i = 0; i < parsedData.length; i++) {
                    const functions = parsedData[i].functions?.split(",")?.map(a => a.trim());
                    let employee = parsedData[i];
                    employee.clientId = client.id;
                    employee.functions = functions;
                    employee.currentSurveyStatus = "Not Started";
                    let errorArr = [];
                    //validate fields
                    if(!employee.name)
                        errorArr.push("Invalid Name");
                    if(!employee.email || !isValidEmail(parsedData[i].email))
                        errorArr.push("Invalid Email");
                    if(client.employees.map(a => a.email).includes(employee.email))
                        errorArr.push("Email already exists");
                    if(!employee.title)
                        errorArr.push("Invalid Title");
                    if(!employee.managementLevel || !levelOptions.includes(employee.managementLevel))
                        errorArr.push("Invalid Level");
                    if(employee.functions?.length === 0 || !employee.functions.every(a => functionOptions.map(a => a.value).includes(a)))
                        errorArr.push("Invalid Function(s)");

                    errors.push(errorArr.join(", "));
                    employees.push(employee);
                }

                if(errors.filter(a => a).length > 0)
                    displayImportError("Data Import Errors", "Please fix the errors with the data and reload the file");

                setParsedData({ employees, headers, errors });
            };

            reader.readAsText(inputFile);
        }
    }

    const handleImport = async() => {
        setShowImportEmployees(false);
        setLoading(true);
        if(!parsedData?.employees)
            showAlert("danger", "Could not Import data");
        
        try {
            var params = {
                method: "POST",
                body: JSON.stringify(parsedData.employees),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                }
            };
            const response = await fetch(`${process.env.REACT_APP_API_URL}Clients/${id}/Employees/bulk`, params);
            if (response.ok) {
                const data = await response.json();
                setClient(data);
                showAlert("success", `Successfully added ${parsedData.employees.length} employees`);
            }
            else {
                showAlert("danger", "Could not add employees");
            }
        } catch (error) {
            showAlert("danger", error.message);
        } finally {
            setFunctions([]);
            setLoading(false);
            handleCloseImportEmployees();
        }
    }

    //Survey
    const [showAddSurvey, setShowAddSurvey] = useState(false);
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().add(14, "days").format("YYYY-MM-DD"));

    const handleAddSurvey = async (event) => {
        event.preventDefault();
        setShowAddSurvey(false);
        try {
            const postData = JSON.stringify({ 
                startDate,
                endDate,
                status: "Open"
            });
            var params = {
                method: "POST",
                body: postData,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                }
            };
            const response = await fetch(`${process.env.REACT_APP_API_URL}Clients/${id}/Surveys`, params);
            if (response.ok) {
                showAlert("success", "Successfully added survey");
                const data = await response.json();
                setClient(data);
            }
            else {
                showAlert("danger", "Could not add survey");
            }
        } catch (error) {
            showAlert("danger", error.message);
        }
    }

    const updateSurvey = async (survey) => {
        try {
            var params = {
                method: "PUT",
                body: JSON.stringify(survey),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                }
            };
            const response = await fetch(`${process.env.REACT_APP_API_URL}Clients/${client.id}/Surveys/${survey.id}`, params);
            if (response.ok) {
                showAlert("success", "Survey updated successfully");
            }
            else {
                showAlert("danger", "Could not update the survey");
            }
        } catch (error) {
            showAlert("danger", error.message);
        }
    }

    const handleCloseSurvey = async (survey) => {
        try {
            survey.status = "Closed";
            var params = {
                method: "PUT",
                body: JSON.stringify(survey),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                }
            };
            const response = await fetch(`${process.env.REACT_APP_API_URL}Clients/${client.id}/Surveys/${survey.id}`, params);
            if (response.ok) {
                const index = client.surveys.findIndex(a => a.id === survey.id);
                client.surveys[index] = survey;
                setClient({ ...client });
                showAlert("success", "Survey closed successfully");
            }
            else {
                showAlert("danger", "Could not close the survey");
            }
        } catch (error) {
            showAlert("danger", error.message);
        }
    }

    const handleSendSurveyToEmployee = (employeeId) => {
        setEmployeeId(employeeId);
        setShowSendSurveyConfirmation(true);
    }
    const sendSurveyToEmployee = async () => {
        setShowSendSurveyConfirmation(false);
        try {
            var params = {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                }
            };
            const response = await fetch(`${process.env.REACT_APP_API_URL}Clients/${client.id}/Surveys/${client.currentSurveyId}/SendSurvey/${employeeId}`, params);
            if (response.ok) {
                setAlertVariant("success");
            }
            else {
                setAlertVariant("danger");
            }
            setAlertMessage(await response.text());
            setAlertShow(true);
        } catch (error) {
            setError(error.message);
        }
    }

    const handleSendSurveyToAll = () => {
        setShowSendSurveyToAllConfirmation(true);
    }

    const sendSurveyToAll = async () => {
        setShowSendSurveyToAllConfirmation(false);
        try {
            var params = {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                }
            };
            const response = await fetch(`${process.env.REACT_APP_API_URL}Clients/${client.id}/Surveys/${client.currentSurveyId}/SendSurveyToAllEmployees`, params);
            if (response.ok) {
                setAlertVariant("success");
            }
            else {
                setAlertVariant("danger");
            }
            setAlertMessage(await response.text());
            setAlertShow(true);
        } catch (error) {
            setError(error.message);
        }
    }

    const hasImportError = parsedData && parsedData.errors.filter(a => a).length > 0;

    if(loading)
        return (
          <div className="h-100 d-flex justify-content-center align-items-center">
            <span className="spinner-border text-primary" style={{width: "4rem", height: "4rem"}} role="status" />
          </div>
        )

    if(error)
        return (<p>Error: {error}</p>)

    return (
        <div className="container-fluid flex-box px-5">
            <Alert variant={alertVariant} show={alertShow} onClose={() => setAlertShow(false)} dismissible>
                {alertMessage}
            </Alert>
            {/* <SurveyLinkAlert
                alertShow={alertShow}
                onClose={}
                clientId={client.id}
                surveyId={client.currentSurveyId}
                employeeId={employeeId}
            /> */}
            <Link to={`/`}>back to Clients</Link>
            <h2 className="pt-2 pb-2">{client.name}</h2>

            <Tabs
                defaultActiveKey="employees"
                id="client-tab"
                className="mb-3">

                <Tab eventKey="employees" title="Employees">
                    <div className="flex-box">
                        <EmployeeGrid
                            rowData={client.employees}
                            clientId={client.id}
                            surveyId={client.currentSurveyId}
                            handleSendSurveyToEmployee={handleSendSurveyToEmployee}
                            handleSendSurveyToAll={handleSendSurveyToAll}
                        />
                        <div className="flex-content- justify-content-start mt-3 mb-3">
                            <Button variant="primary" onClick={handleShow}>
                                Add Employee
                            </Button>
                            <Button variant="secondary" className="ms-3" onClick={() => setShowImportEmployees(true)}>
                                Import Employees
                            </Button>
                        </div>
                    </div>
                    
                    { showSendSurveyConfirmation &&
                        <ConfirmDialog 
                            showModal={showSendSurveyConfirmation}
                            setShowModal={setShowSendSurveyConfirmation}
                            confirmButtonTitle="Send"
                            cancelButtonTitle="Cancel"
                            message={`Are you sure you would like to send this survey to ${client.employees.find(a => a.id === employeeId).email}?`}
                            handleConfirm={sendSurveyToEmployee}
                        />
                    }
                    { showSendSurveyToAllConfirmation &&
                        <ConfirmDialog 
                            showModal={showSendSurveyToAllConfirmation}
                            setShowModal={setShowSendSurveyToAllConfirmation}
                            confirmButtonTitle="Send"
                            cancelButtonTitle="Cancel"
                            message="Are you sure you would like to send this survey to all employees in this organization?"
                            handleConfirm={sendSurveyToAll}
                        />
                    }
                </Tab>

                <Tab eventKey="surveys" title="Surveys">
                    <div className="flex-box">
                        <SurveyGrid
                            rowData={client.surveys}
                            onClick={handleCloseSurvey}
                            clientId={client.id}
                            updateSurvey={updateSurvey}
                            canCloseSurvey={client.employees.length > 2}
                        />
                        <div className="flex-content-auto mb-3">
                            <Button variant="primary" className="mt-3" onClick={() => setShowAddSurvey(true)}>
                                Add Survey
                            </Button>
                        </div>
                    </div>
                </Tab>
            </Tabs>

            {/* Employee Modal */}
            <Modal size="large" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Employee</Modal.Title>
                </Modal.Header>
                 <Form onSubmit={handleSave}>  {/*noValidate validated={validated} onSubmit={handleSave}> */}
                    <Modal.Body>
                        <Form.Group controlId="name">
                            <Form.Label>Name: </Form.Label>
                            <Form.Control autoFocus type="text" onChange={(event) => setName(event.target.value)} required placeholder="Name" />
                        </Form.Group>
                        <Form.Group controlId="email">
                            <Form.Label>Email: </Form.Label>
                            <Form.Control 
                                type="email" 
                                onChange={(event) => setEmail(event.target.value)} 
                                required 
                                pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$" 
                                // isInvalid={ !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email) } 
                                placeholder="Email" 
                            />
                            <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="title">
                            <Form.Label>Title: </Form.Label>
                            <Form.Control type="text" onChange={(event) => setTitle(event.target.value)} required placeholder="Title" />
                        </Form.Group>
                        <Form.Group controlId="managementLevel">
                            <Form.Label>Management Level: </Form.Label>
                            {/* <Form.Control type="text" onChange={handleManagementLevelChange} required placeholder="ManagementLevel" /> */}
                            <Form.Select aria-label="Management Level"
                            required
                            onChange={(event) => setManagementLevel(event.target.value)}>
                                <option value="">Select Management Level</option>
                                <option value="L0">L0</option>
                                <option value="L1">L1</option>
                                <option value="L2">L2</option>
                                <option value="L3+">L3+</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="functions">
                            <Form.Label>Functions: </Form.Label>
                            {/* <Form.Control type="text" onChange={handleFunctionsChange} placeholder="Functions" /> */}
                            <MultiTextInput 
                                required
                                setValue={setFunctions}
                                value={functions}
                                placeholder="Select function(s)..."
                                creatable={false}
                                options={functionOptions}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* Import Employees Modal */}
            <Modal dialogClassName="modal-70w" show={showImportEmployees} onHide={handleCloseImportEmployees}>
                <Modal.Header closeButton>
                    <Modal.Title>Import Employees</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label htmlFor="csvInput">Select CSV File</label>
                    <input
                        onChange={handleFileChange}
                        id="csvInput"
                        name="file"
                        type="File"
                        accept=".csv"
                        className="ms-1"
                    />
                    { parsedData &&
                        <div className="mt-3" style={{maxHeight: "500px", overflowY: "scroll"}}>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Title</th>
                                        <th>Management Level</th>
                                        <th>Functions</th>
                                        { hasImportError && <th>Errors</th> }
                                    </tr>
                                </thead>
                                <tbody>
                                    { parsedData.employees.map((employee, index) => {
                                        return (
                                            <tr>
                                                { Object.keys(employee).filter(a => a !== "clientId" && a !== "currentSurveyStatus").map((key, idx) => {
                                                    return (
                                                        <td key={idx}>{key === "functions" ? employee[key].join(", ") : employee[key]}</td>
                                                    );
                                                })}
                                                { hasImportError && <td style={{color: "red"}}>{parsedData.errors[index]}</td> }
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    }
                    { parsedData && !hasImportError && <div className="mt-3">Are you sure you want to import these <b>{parsedData.employees.length}</b> employees to <b>{client.name}</b>?</div> }
                    <div className="mt-3">
                        <Alert variant="danger" show={showImportError} onClose={() => setShowImportError(false)}>
                            <Alert.Heading>{importError.header}</Alert.Heading>
                            <p>{importError.message}</p>
                        </Alert>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseImportEmployees}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleImport} disabled={showImportError || hasImportError}>
                        Import
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Survey Modal */}
            <Modal variant="large" show={showAddSurvey} onHide={() => setShowAddSurvey(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Survey</Modal.Title>
                </Modal.Header>
                 <Form onSubmit={handleAddSurvey}>  {/*noValidate validated={validated} onSubmit={handleAddSurvey}> */}
                    <Modal.Body>
                        <Form.Group controlId="startDate">
                            <Form.Label>Start Date: </Form.Label>
                            <Form.Control type="date" value={startDate} onChange={(event) => { setStartDate(event.target.value); }} required placeholder="Start Date" />
                        </Form.Group>
                        <Form.Group controlId="endDate">
                            <Form.Label>End Date: </Form.Label>
                            <Form.Control type="date" value={endDate} onChange={(event) => { setEndDate(event.target.value); }} required placeholder="End Date" />
                        </Form.Group>
                        <div className="pt-3 fst-italic">Adding a new Survey will Close any open Surveys</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowAddSurvey(false)}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </div>
    )
}