import { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import { ArcElement, BarElement, CategoryScale, Chart, Filler, Legend, LineElement, LinearScale, PointElement, RadialLinearScale, Tooltip } from 'chart.js';
import { RadarChart } from './charts/RadarChart';
import colorLib from '@kurkle/color';
import { json } from "../data/survey_json.js";
import { surveyResultData } from "../data/surveyResultData.js";
import { summarizedSurveyResultData } from "../data/summarizedSurveyResultData.js";
import { ListGroupItemEllipsis } from "./controls/ListGroupItemEllipsis.js";
import GroupMetrics from "./GroupMetrics.js";
import QuestionMetrics from "./QuestionMetrics.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { ListGroup, Offcanvas, Form, Container, Row, Col } from "react-bootstrap";
import OverallMetrics from "./OverallMetrics.js";

Chart.register(LineElement, PointElement, RadialLinearScale, Filler, Tooltip, Legend, LinearScale, ArcElement, CategoryScale, BarElement);

const COLORS = [
  '#545454',
  //'#4dc9f6',
  '#f67019',
  '#f53794',
  '#537bc4',
  '#8549ba',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#58595b',
];

const colorPalette1 = {
  red: '#ff0000',
  orange: '#ffa700',
  yellow: '#fff400',
  lightGreen: '#a3ff00',
  green: '#2cba00',
  blue: '#0077c0',
  purple: '#9966ff',
}

const colorPalette2 = {
  red: "#cc0000",
  orange: "#e69138",
  yellow: "#f1c232",
  lightGreen: "#86bb6f",
  green: "#6aa84f",
  blue: "#3d85c6",
  purple: "#8d4fa8",
}

const colorPalette3 = {
  red: "#ff0000",
  orange: "#ff7c00",
  yellow: "#ffec00",
  lightGreen: "#44dc00",
  green: "#2c8f00",
  blue: "#004899",
  purple: "#63008f",
}

const colorPalette4 = {
  red: "#ed2938",
  orange: "#ff8c01",
  yellow: "#ffe733",
  lightGreen: "#44dc00",
  green: "#006b3e",
  blue: "#004899",
  purple: "#63008f",
}

const colorPalette5 = {
  red: "#fd3f46",
  orange: "#ff8b00",
  yellow: "#ffe77a",
  lightGreen: "#b7eb63",
  green: "#76d136",
  blue: "#004899",
  purple: "#63008f",
}

const colorPalette6 = {
  red: "#ff4c4c",
  orange: "#ffc14c",
  yellow: "#fff74c",
  lightGreen: "#a6e293",
  green: "#6bcf4c",
  blue: "#4c6bcf",
  purple: "#b04ccf",
}

const colorPalette7 = {
  red: "#f33d5a",
  orange: "#ffc14c",
  yellow: "#fff74c",
  lightGreen: "#a6e293",
  green: "#5af33d",
  blue: "#3d5af3",
  purple: "#b04ccf",
}

const colorPalette8 = {
  red: "#f94449", //"#f33d5a",
  redOrange: "#fc843e", //"#fb7579",
  orange: "#ffc333", //"#ffaa33",
  orangeYellow: "#fddf33", //"#f99a44",
  yellow: "#fafa33",
  yellowGreen: "#b3e540", //"#f9f544",
  green: "#6bcf4c",
  lightGreen: "#5af33d", //#a6e293
  greenBlue: "#5495A0",
  blue: "#3d5af3",
  purple: "#b04ccf",
}

const colors = colorPalette8;

function color(index) {
  return COLORS[index % COLORS.length];
}

function transparentize(value, opacity) {
  //return value;
  const alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
}

function getColorFromScore(rank) {
  if (rank <= 1.5)
    return colors.red;
  if (rank > 1.5 && rank <= 2)
    return colors.redOrange;
  if (rank > 2 && rank <= 2.5)
      return colors.orange;
  if (rank > 2.5 && rank <= 3)
    return colors.orangeYellow;
  if (rank > 3 && rank <= 3.5)
    return colors.yellow;
  if(rank > 3.5 && rank <= 4)
    return colors.yellowGreen;
  if(rank > 4 && rank <= 4.5)
    return colors.green;
  if (rank > 4.5)
    return colors.lightGreen;
};

function getColorFromAlignmentRank(rank) {
  if (rank <= .25)
    return colors.lightGreen;
  if (rank > .25 && rank <= .5)
    return colors.green;
  if (rank > .5 && rank <= .75)
      return colors.yellowGreen;
  if (rank > .75 && rank <= 1)
    return colors.yellow;
  if (rank > 1 && rank <= 1.25)
    return colors.orangeYellow;
  if(rank > 1.25 && rank <= 1.5)
    return colors.orange;
  if(rank > 1.5 && rank <= 1.75)
    return colors.redOrange;
  if (rank > 1.75)
    return colors.red;
};

function getClassFromScore(rank) {
  if (rank <= 1.5)
    return "bg-red";
  if (rank > 1.5 && rank <= 2)
    return "bg-redOrange";
  if (rank > 2 && rank <= 2.5)
      return "bg-orange";
  if (rank > 2.5 && rank <= 3)
    return "bg-orangeYellow";
  if (rank > 3 && rank <= 3.5)
    return "bg-yellow";
  if (rank > 3.5 && rank <= 4)
    return "bg-yellowGreen";
  if (rank > 4 && rank <= 4.5)
    return "bg-green";
  if (rank > 4.5)
    return "bg-lightGreen";
};

function getClassFromAlignment(rank) {
  if (rank <= .25)
    return "bg-lightGreen";
  if (rank > .25 && rank <= .5)
    return "bg-green";
  if (rank > .5 && rank <= .75)
      return "bg-yellowGreen";
  if (rank > .75 && rank <= 1)
    return "bg-yellow";
  if (rank > 1 && rank <= 1.25)
    return "bg-orangeYellow";
  if (rank > 1.25 && rank <= 1.5)
    return "bg-orange";
  if (rank > 1.5 && rank <= 1.75)
    return "bg-redOrange";
  if (rank > 1.75)
    return "bg-red";
};

const getQuestionColorCounts = (scores) => {
  const values = { red: 0, redOrange: 0, orange: 0, orangeYellow: 0, yellow: 0, yellowGreen: 0, green: 0, lightGreen: 0 };
  scores.forEach(a => {
    if (a <= 1.5)
      values.red += 1;
    else if (a > 1.5 && a <= 2)
      values.redOrange += 1;
    else if (a > 2 && a <= 2.5)
      values.orange += 1;
    else if (a > 2.5 && a <= 3)
      values.orangeYellow += 1;
    else if (a > 3 && a <= 3.5)
      values.yellow += 1;
    else if (a > 3.5 && a <= 4)
      values.yellowGreen += 1;
    else if (a > 4 && a <= 4.5)
      values.green += 1;
    else if (a > 4.5)
      values.lightGreen += 1;
  });
  return values;
}

const getQuestionAlignmentColorCounts = (alignmentValues) => {
  const values = { red: 0, redOrange: 0, orange: 0, orangeYellow: 0, yellow: 0, yellowGreen: 0, green: 0, lightGreen: 0 };
  alignmentValues.forEach(a => {
    if (a <= .25)
      values.lightGreen += 1;
    else if (a > .25 && a <= .5)
      values.green += 1;
    else if (a > .5 && a <= .75)
      values.yellowGreen += 1;
    else if (a > .75 && a <= 1)
      values.yellow += 1;
    else if (a > 1 && a <= 1.25)
      values.orangeYellow += 1;
    else if (a > 1.25 && a <= 1.5)
      values.orange += 1;
    else if (a > 1.5 && a <= 1.75)
      values.redOrange += 1;
    else if (a > 1.75)
      values.red += 1;
  });
  return values;
}

const CHART_COLORS = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)'
};

const NAMED_COLORS = [
  CHART_COLORS.red,
  CHART_COLORS.orange,
  CHART_COLORS.yellow,
  CHART_COLORS.green,
  CHART_COLORS.blue,
  CHART_COLORS.purple,
  CHART_COLORS.grey,
];

const summaryRadarData = {
  labels: ["Strategy", "Work Process", "Structure & Governance", "Information & Metrics", "People & Rewards", "Continuous Alignment", "Leadership & Culture", "Organization Alignment", "Guiding Transformation"],
  datasets: [
    // {
    //   label: "Average Scores",
    //   data: summarizedSurveyResultData.map(a => a.average),
    //   borderColor: CHART_COLORS.green,
    //   backgroundColor: transparentize(CHART_COLORS.green),
    //   fill: 'none',
    //   parsing: {
    //     // xAxisKey: 'questionId',
    //     rAxisKey: 'alignmentRank',
    //   }
    // },
    {
      label: "Alignment Rank",
      data: summarizedSurveyResultData.map(a => a.alignmentRank),
      borderColor: '#545454', //'#0077c0',
      backgroundColor: transparentize('#545454'), //transparentize('#0077c0'),
      fill: 'none',
      parsing: {
        // xAxisKey: 'questionId',
        rAxis: 'alignmentRank',
      }
    },
    {
      data: summarizedSurveyResultData.map(a => 2.9), //[2.9,2.9,2.9,2.9,2.9,2.9,2.9,2.9,2.9], //[1, 1, 1, 1, 1, 1, 1, 1, 1],
      fill: true, //'origin',
      backgroundColor: transparentize(colors.red, 0.2),
      // backgroundColor: ({chart: {ctx}}) => {
      //   const gradient = ctx.createLinearGradient(0, 0, 0, 20);
      //   gradient.addColorStop(0, 'rgba(250,174,50,1)');   
      //   gradient.addColorStop(1, 'rgba(250,174,50,0)');
      //   return gradient;
      // },
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: summarizedSurveyResultData.map(a => 3.2), //[3.2,3.2,3.2,3.2,3.2,3.2,3.2,3.2,3.2], //[2, 2, 2, 2, 2, 2, 2, 2, 2],
      fill: '-1',
      backgroundColor: transparentize(colors.orange, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: summarizedSurveyResultData.map(a => 3.4), //[3.4, 3.4, 3.4, 3.4, 3.4, 3.4, 3.4, 3.4, 3.4], //[3, 3, 3, 3, 3, 3, 3, 3, 3],
      fill: '-1',
      backgroundColor: transparentize(colors.yellow, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    // {
    //   data: [4, 4, 4, 4, 4, 4, 4, 4, 4],
    //   fill: '-1',
    //   backgroundColor: transparentize(colors.lightGreen, 0.2),
    //   pointRadius: 0,
    //   borderWidth: 0,
    //   borderColor: 'white',
    // },
    {
      data: summarizedSurveyResultData.map(a => 3.6), //[5, 5, 5, 5, 5, 5, 5, 5, 5],
      fill: '-1',
      backgroundColor: transparentize(colors.green, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
  ],
};

const summaryAverageRadarData = {
  labels: ["Strategy", "Work Process", "Structure & Governance", "Information & Metrics", "People & Rewards", "Continuous Alignment", "Leadership & Culture", "Organization Alignment", "Guiding Transformation"],
  datasets: [
    {
      label: "Average Scores",
      data: summarizedSurveyResultData.map(a => a.average),
      borderColor: '#545454', //'#0077c0',
      backgroundColor: transparentize('#545454'), //transparentize('#0077c0'),
      fill: 'none',
      parsing: {
        // xAxisKey: 'questionId',
        rAxisKey: 'average',
      }
    },
    {
      data: summarizedSurveyResultData.map(a => 2.9), //[2.9,2.9,2.9,2.9,2.9,2.9,2.9,2.9,2.9], //[1, 1, 1, 1, 1, 1, 1, 1, 1],
      fill: true, //'origin',
      backgroundColor: transparentize(colors.red, 0.2),
      // backgroundColor: ({chart: {ctx}}) => {
      //   const gradient = ctx.createLinearGradient(0, 0, 0, 20);
      //   gradient.addColorStop(0, 'rgba(250,174,50,1)');   
      //   gradient.addColorStop(1, 'rgba(250,174,50,0)');
      //   return gradient;
      // },
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: summarizedSurveyResultData.map(a => 3.2), //[3.2,3.2,3.2,3.2,3.2,3.2,3.2,3.2,3.2], //[2, 2, 2, 2, 2, 2, 2, 2, 2],
      fill: '-1',
      backgroundColor: transparentize(colors.orange, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: summarizedSurveyResultData.map(a => 3.4), //[3.4, 3.4, 3.4, 3.4, 3.4, 3.4, 3.4, 3.4, 3.4], //[3, 3, 3, 3, 3, 3, 3, 3, 3],
      fill: '-1',
      backgroundColor: transparentize(colors.yellow, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    // {
    //   data: [4, 4, 4, 4, 4, 4, 4, 4, 4],
    //   fill: '-1',
    //   backgroundColor: transparentize(colors.lightGreen, 0.2),
    //   pointRadius: 0,
    //   borderWidth: 0,
    //   borderColor: 'white',
    // },
    {
      data: summarizedSurveyResultData.map(a => 3.6), //[5, 5, 5, 5, 5, 5, 5, 5, 5],
      fill: '-1',
      backgroundColor: transparentize(colors.green, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
  ],
};

const questionAverageRadarData = {
  labels: surveyResultData.map(a => ("Q" + a.questionId)),
  datasets: [
    {
      label: "Average Scores",
      data: surveyResultData.map(a => a.average),
      borderColor: '#545454', //'#0077c0',
      backgroundColor: transparentize('#545454'), //'#0077c0'),
      fill: 'none',
      parsing: {
        // xAxisKey: 'questionId',
        rAxisKey: 'average',
      }
    },
    {
      data: surveyResultData.map(a => 2.9), //[2.9,2.9,2.9,2.9,2.9,2.9,2.9,2.9,2.9], //[1, 1, 1, 1, 1, 1, 1, 1, 1],
      fill: true, //'origin',
      backgroundColor: transparentize(colors.red, 0.2),
      // backgroundColor: ({chart: {ctx}}) => {
      //   const gradient = ctx.createLinearGradient(0, 0, 0, 20);
      //   gradient.addColorStop(0, 'rgba(250,174,50,1)');   
      //   gradient.addColorStop(1, 'rgba(250,174,50,0)');
      //   return gradient;
      // },
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: surveyResultData.map(a => 3.2), //[3.2,3.2,3.2,3.2,3.2,3.2,3.2,3.2,3.2], //[2, 2, 2, 2, 2, 2, 2, 2, 2],
      fill: '-1',
      backgroundColor: transparentize(colors.orange, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: surveyResultData.map(a => 3.4), //[3.4, 3.4, 3.4, 3.4, 3.4, 3.4, 3.4, 3.4, 3.4], //[3, 3, 3, 3, 3, 3, 3, 3, 3],
      fill: '-1',
      backgroundColor: transparentize(colors.yellow, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    // {
    //   data: [4, 4, 4, 4, 4, 4, 4, 4, 4],
    //   fill: '-1',
    //   backgroundColor: transparentize(colors.lightGreen, 0.2),
    //   pointRadius: 0,
    //   borderWidth: 0,
    //   borderColor: 'white',
    // },
    {
      data: surveyResultData.map(a => 3.6), //[5, 5, 5, 5, 5, 5, 5, 5, 5],
      fill: '-1',
      backgroundColor: transparentize(colors.green, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
  ],
};

const pieData = {
  labels: ["Strategy", "Work Process", "Structure & Governance", "Information & Metrics", "People & Rewards", "Continuous Alignment", "Leadership & Culture", "Organization Alignment", "Guiding Transformation"],
  datasets: [
    {
      label: "Alignment Rank",
      data: summarizedSurveyResultData.map(a => a.alignmentRank),
      hoverOffset: 30,
      borderWidth: 1,
      backgroundColor: [
        '#0077c0',
        '#ff0000',
        '#ffa700',
        '#fff400',
        '#a3ff00',
        '#2cba00',
        'orange',
        'blue',
        'purple'
      ]
    },
  ],
};

const barData = {
  labels: surveyResultData.map(a => ("Q" + a.questionId)),
  datasets: [
    {
      label: "Alignment Rank",
      data: surveyResultData.map(a => a.alignmentRank),
      hoverOffset: 30,
      borderWidth: 1,
      backgroundColor: colors.orange, //summarizedSurveyResultData.map(a => getColorFromAlignmentRank(a.alignmentRank))
    },
    {
      label: "Average",
      data: surveyResultData.map(a => a.average),
      hoverOffset: 30,
      borderWidth: 1,
      backgroundColor: colors.blue, //summarizedSurveyResultData.map(a => getColorFromAlignmentRank(a.alignmentRank))
    },
  ],
};
//summarizedSurveyResultData.map(a => getColorFromAlignmentRank(a.alignmentRank))

const scatterData = {
  datasets: [
    {
      label: 'Alignment Rank',
      backgroundColor: '#fd7702',
      data: surveyResultData.map(({ questionId, alignmentRank }) => ({ ['x']: questionId, ['y']: alignmentRank })),
    },
    {
      label: 'Average Score',
      backgroundColor: '#003366',
      data: surveyResultData.map(({ questionId, average }) => ({ ['x']: questionId, ['y']: average })),
    }
  ]
}

const lineData = {
  labels: surveyResultData.map(a => ("Q" + a.questionId)),
  datasets: [
    {
      label: 'Alignment Rank',
      borderColor: '#fd7702', //colors.orange, //'#0077c0',
      data: surveyResultData.map(a => a.alignmentRank),
    },
    {
      label: 'Average Score',
      borderColor: '#003366', //colors.blue, //'#545454',
      data: surveyResultData.map(a => a.average),
    }
  ]
}

const areaData = {
  labels: surveyResultData.map(a => ("Q" + a.questionId)),
  datasets: [
    {
      label: 'Alignment Rank',
      backgroundColor: transparentize('#b3cde0', .2), //'#0077c0', .2),
      fill: true,
      pointRadius: 0,
      data: surveyResultData.map(({ questionId, alignmentRank }) => ({ ['x']: questionId, ['y']: alignmentRank })),
    },
    {
      label: 'Average Score',
      backgroundColor: transparentize('#005b96', .2), //'#545454', .2),
      fill: true,
      pointRadius: 0,
      data: surveyResultData.map(({ questionId, average }) => ({ ['x']: questionId, ['y']: average })),
    }
  ]
}

function roundNumber(data, type, row) {
  return (Math.round(data * 100) / 100);
}

const columns = [
  { data: "questionId", title: "Question #" },
  { data: "questionGroup", title: "Group" },
  { data: "average", title: "Average Score", render: roundNumber },
  { data: "alignmentRank", title: "Alignment Rank", render: roundNumber },
]
const dataTableConfig = {
  data: surveyResultData,
  columns: columns,
  searching: false,
  paging: false,
  select: true,
}

const summaryColumns = [
  { data: "questionGroup", title: "Group" },
  { data: "average", title: "Average Score", render: roundNumber },
  { data: "alignmentRank", title: "Alignment Rank", render: roundNumber },
]

const summaryDataTableConfig = {
  data: summarizedSurveyResultData,
  columns: summaryColumns,
  searching: false,
  paging: false,
  select: true,
}

const questions = json.pages.flatMap(a => a.elements).filter(a => a.type === "rating");

function scaleAlignmentIndex(score) {
  const roundedScore = Math.round(score * 10) / 10;
  if (roundedScore <= 2.9)
    return roundedScore / 2.9;
  if (roundedScore <= 3.2)
    return (roundedScore - 2.9) / 0.3 + 1;
  if (roundedScore <= 3.4)
    return (roundedScore - 3.2) / 0.2 + 2;
  if (roundedScore <= 4)
    return (roundedScore - 3.4) / 0.6 + 3;
  return roundedScore;
}

function scaleAverage(score) {
  return score;
  const roundedScore = Math.round(score * 10) / 10;
  if (roundedScore <= 2.9)
    return roundedScore / 2.9;
  if (roundedScore <= 3.2)
    return (roundedScore - 2.9) / 0.3 + 1;
  if (roundedScore <= 3.4)
    return (roundedScore - 3.2) / 0.2 + 2;
  if (roundedScore <= 4)
    return (roundedScore - 3.4) / 1.6 + 3;
  return roundedScore;
}

const round = (value) => Math.round(value * 10) / 10;

function getGroupLabel(questionId) {
  if (questionId === 5) return "Strategy";
  if (questionId === 15) return "Work Process";
  if (questionId === 25) return "Structure & Governance";
  if (questionId === 34) return "Information & Metrics";
  if (questionId === 41) return "People & Rewards";
  if (questionId === 47) return "Continuous Alignment";
  if (questionId === 54) return "Leadership & Culture";
  if (questionId === 61) return "Organization & Alignment";
  if (questionId === 70) return "Guiding Transformation";
  return "";
}

const questionGroups = [
  "Strategy",
  "Work Process",
  "Structure & Governance",
  "Information & Metrics",
  "People & Rewards",
  "Continuous Alignment",
  "Leadership & Culture",
  "Organization & Alignment",
  "Guiding Transformation"
];

export default function SurveyResults(props) {
  const { clientId, surveyId } = useParams();
  // const { state } = useContext(AppContext);
  // const client = state.clients[match.params.id];
  const [clientName, setClientName] = useState();
  const [questionMetrics, setQuestionMetrics] = useState([]);
  const [questionMetric, setQuestionMetric] = useState();
  const [commentMetrics, setCommentMetrics] = useState([]);
  const [commentMetric, setCommentMetric] = useState();
  const [questionTitle, setQuestionTitle] = useState();
  const [questionGroupMetrics, setQuestionGroupMetrics] = useState([]);
  const [questionGroupMetric, setQuestionGroupMetric] = useState();
  const [questionColorCounts, setQuestionColorCounts] = useState({ red: 0, orange: 0, yellow: 0, green: 0 });
  const [questionAlignmentColorCounts, setQuestionAlignmentColorCounts] = useState({ red: 0, orange: 0, yellow: 0, green: 0 });
  const [questionGroupColorCounts, setQuestionGroupColorCounts] = useState({ red: 0, orange: 0, yellow: 0, green: 0 });
  const [pageView, setPageView] = useState("overall"); // overall | group | question
  const [enabledLevels, setEnabledLevels] = useState(["All"]);
  const [showAverageScore, setShowAverageScore] = useState(true);
  const [showDegreeOfAlignment, setShowDegreeOfAlignment] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showComments, setShowComments] = useState(false);
  const [commentQuestion, setCommentQuestion] = useState(null);

  useEffect(() => {
    getQuestionMetrics();
  }, [clientId, surveyId]);

  const changePageView = (value) => {
    if(value === "overall")
      setQuestionGroupMetric(null);
    setPageView(value);
  }

  const getQuestionMetrics = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}Clients/${clientId}/SurveyMetrics/${surveyId}`);
      if (!response.ok) {
        throw new Error('Could not get survey metrics for client.');
      }
      const surveyMetric = await response.json();

      setClientName(surveyMetric.clientName);
      setQuestionMetrics(surveyMetric.questionMetrics);
      setQuestionMetric(surveyMetric.questionMetrics[0]);
      setQuestionTitle(questions[0].title);
      const groupMetrics = getQuestionGroupMetrics(surveyMetric.questionMetrics);
      setQuestionGroupMetrics(groupMetrics);
      //setQuestionGroupMetric(groupMetrics[0]);
      const questionColors = getQuestionColorCounts(surveyMetric.questionMetrics.map(a => a.metricScores.find(b => b.name === "All").average));
      setQuestionColorCounts(questionColors);
      const questionAlignmentColors = getQuestionAlignmentColorCounts(surveyMetric.questionMetrics.map(a => a.metricScores.find(b => b.name === "All").alignmentIndex));
      setQuestionAlignmentColorCounts(questionAlignmentColors);
      const questionGroupColors = getQuestionColorCounts(groupMetrics.map(a => a.metricScores.find(b => b.name === "All").average));
      setQuestionGroupColorCounts(questionGroupColors);

      setCommentMetrics(surveyMetric.commentMetrics);
      setCommentMetric(surveyMetric.commentMetrics[0]);

      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  function getLineWidth(context) {
    const acceptableIndexes = [ 0, 8, 17, 27, 33, 39, 44, 50, 56 ]
    if(acceptableIndexes.includes(context.index)) {
      // if(questionGroupMetric.questionGroup === "Strategy" && (context.index === 0 || context.index === 8))
      //   return 6;
      return 2;
    }
    return 0;
  }

  function getPointLabelFont(context) {
    if(questionGroupMetric && questionGroupMetric.questionGroup === context.label)
      return { size: 12, weight: "normal", style: "oblique" };
    return { size: 12, weight: "normal" };
  }

  const onPointClick = index => {
    const questionMetric = questionMetrics[index];
    setQuestionMetric(questionMetric);
    const questionGroupMetric = questionGroupMetrics.find(a => a.questionGroup === questionMetric.questionGroup);
    setQuestionGroupMetric(questionGroupMetric);
    const commentMetric = commentMetrics.find(a => a.questionGroup === questionMetric.questionGroup);
    setCommentMetric(commentMetric);
    setQuestionTitle(questions[index].title);
    setPageView("question");
  }

  const onLabelClick = group => {
    const questionGroupMetric = questionGroupMetrics.find(a => a.questionGroup === group);
    setQuestionGroupMetric(questionGroupMetric);
    const commentMetric = commentMetrics.find(a => a.questionGroup === group);
    setCommentMetric(commentMetric);
    setPageView("group");
  }

  const setEnabledLegendItems = enabledLevels => {
    setEnabledLevels(enabledLevels);
  }

  const onCommentsClick = () => {
    const page = json.pages.find(a => a.name === questionGroupMetric.questionGroup);
    const commentQuestion = page.elements.find(a => a.type === "comment");
    setCommentQuestion(commentQuestion);
    setShowComments(true);
  }

  const onGroupPointClick = index => {
    setQuestionGroupMetric(questionGroupMetrics[index]);
    setPageView("group");
  }

  const getDataSets = (questionMetrics) => {
    if(questionMetrics.length === 0)
      return [];
    
    const dataSets = [];
    let index = 0;
    for(const name of questionMetrics[0].metricScores.map(a => a.name)) {
      let color = COLORS[index];
      const hidden = name !== "All";
      const dataSet = {
        label: name,
        data: questionMetrics.map(a => a.metricScores.find(b => b.name === name).average),
        borderColor: color,
        backgroundColor: transparentize(color),
        fill: 'none',
        hidden: hidden,
        // parsing: {
        //   rAxisKey: 'alignmentRank',
        // }
      };
      dataSets.push(dataSet);
      index++;
    }
    return dataSets;
  }

  const backgroundDataSets = [
    {
      data: questionMetrics.map(a => 1.5), //questionMetrics.map(a => 2.9),
      fill: true, //'origin',
      backgroundColor: transparentize(colors.red, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: questionMetrics.map(a => 2), //questionMetrics.map(a => 2.9),
      fill: true, //'origin',
      backgroundColor: transparentize(colors.redOrange, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: questionMetrics.map(a => 2.5), //questionMetrics.map(a => 3.2),
      fill: '-1',
      backgroundColor: transparentize(colors.orange, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: questionMetrics.map(a => 3), //questionMetrics.map(a => 3.2),
      fill: '-1',
      backgroundColor: transparentize(colors.orangeYellow, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: questionMetrics.map(a => 3.5), //questionMetrics.map(a => 3.4),
      fill: '-1',
      backgroundColor: transparentize(colors.yellow, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: questionMetrics.map(a => 4), //questionMetrics.map(a => 3.4),
      fill: '-1',
      backgroundColor: transparentize(colors.yellowGreen, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    // {
    //   data: questionMetrics.map(a => 4), //questionMetrics.map(a => 3.6),
    //   fill: '-1',
    //   backgroundColor: transparentize(colors.lightGreen, 0.2),
    //   pointRadius: 0,
    //   borderWidth: 0,
    //   borderColor: 'white',
    // },
    {
      data: questionMetrics.map(a => 4.5), //questionMetrics.map(a => 3.6),
      fill: '-1',
      backgroundColor: transparentize(colors.green, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: questionMetrics.map(a => 5), //questionMetrics.map(a => 3.6),
      fill: '-1',
      backgroundColor: transparentize(colors.lightGreen, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
  ];

  const backgroundOldDataSets = [
    {
      data: questionMetrics.map(a => 1.5), //questionMetrics.map(a => 2.9),
      fill: true, //'origin',
      backgroundColor: transparentize(colors.red, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: questionMetrics.map(a => 2.5), //questionMetrics.map(a => 3.2),
      fill: '-1',
      backgroundColor: transparentize(colors.orange, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: questionMetrics.map(a => 3.5), //questionMetrics.map(a => 3.4),
      fill: '-1',
      backgroundColor: transparentize(colors.yellow, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: questionMetrics.map(a => 4.5), //questionMetrics.map(a => 3.6),
      fill: '-1',
      backgroundColor: transparentize(colors.lightGreen, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: questionMetrics.map(a => 5), //questionMetrics.map(a => 3.6),
      fill: '-1',
      backgroundColor: transparentize(colors.green, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
  ];

  const getData = (name) => {
    if(questionMetrics.length === 0)
      return;
    if(questionMetrics[0].metricScores.some(a => a.name === name)) {
      // if(name == "AlignmentIndex")
      //   return questionMetrics.flatMap(a => scaleAlignmentIndex(a.metricScores.find(a => a.name === name)));
      return questionMetrics.flatMap(a => a.metricScores.find(b => b.name === name));
    }
  }
  
  const questionRadarData = {
    labels: questionMetrics.map(a => getGroupLabel(a.questionId)),
    datasets: [
      ...getDataSets(questionMetrics),
      //...backgroundOldDataSets
      ...backgroundDataSets
    ],
  };
  
  const questionPolarAreaData = {
    labels: questionMetrics.map(a => `Q${a.questionId}`),
    datasets: [
      ...getDataSets(questionMetrics),
      // {
      //   label: "Alignment Index",
      //   data: questionMetrics.map(a => a.alignmentIndex),
      //   // borderColor: '#545454', //'#0077c0',
      //   backgroundColor: questionMetrics.map(a => getColorFromAlignmentRank(a.alignmentIndex)),
      //   fill: 'none',
      // },
    ],
  };

  const questionAverageRadarData = {
    labels: questionMetrics.map(a => a.questionName),
    datasets: [
      {
        label: "Average Scores",
        data: questionMetrics.map(a => scaleAverage(a.average)),
        borderColor: '#545454', //'#0077c0',
        backgroundColor: transparentize('#545454'), //'#0077c0'),
        fill: 'none',
        parsing: {
          // xAxisKey: 'questionId',
          rAxisKey: 'average',
        }
      },
      ...backgroundDataSets
    ],
  };



  const backgroundGroupDataSets = [
    {
      data: questionGroupMetrics.map(a => 1), //questionMetrics.map(a => 2.9),
      fill: true, //'origin',
      backgroundColor: transparentize(colors.red, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: questionGroupMetrics.map(a => 2), //questionMetrics.map(a => 3.2),
      fill: '-1',
      backgroundColor: transparentize(colors.orange, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: questionGroupMetrics.map(a => 3), //questionMetrics.map(a => 3.4),
      fill: '-1',
      backgroundColor: transparentize(colors.yellow, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
    {
      data: questionGroupMetrics.map(a => 4), //questionMetrics.map(a => 3.6),
      fill: '-1',
      backgroundColor: transparentize(colors.green, 0.2),
      pointRadius: 0,
      borderWidth: 0,
      borderColor: 'white',
    },
  ] 

  const getQuestionGroupMetrics = (questionMetrics) => {
    const values = [];
    questionGroups.forEach(groupName => {
      const questions = questionMetrics.filter(a => a.questionGroup === groupName);
      const length = questions.length;

      const metricScoreSums = {};
      const metricAlignmentScoreSums = {};
      const names = questions[0].metricScores.map(a => a.name);
      for(const name of names) {
        metricScoreSums[name] = 0;
        metricAlignmentScoreSums[name] = 0;
      }

      questions.forEach(question => {
        for(const name of names) {
          const metricScore = question.metricScores.find(a => a.name === name);
          metricScoreSums[name] += metricScore.average;
          metricAlignmentScoreSums[name] += metricScore.alignmentIndex;
        }
      });

      let metricScores = [];
      for(const name in metricScoreSums) {
        metricScores.push({
          name: name,
          average: metricScoreSums[name] / length,
          alignmentIndex: metricAlignmentScoreSums[name] / length,
        });
      }

      values.push({
        clientId: clientId,
        surveyId: surveyId,
        questionGroup: groupName,
        metricScores: metricScores
      });
    });
    return values;
  }

  

  const questionGroupRadarData = {
    labels: questionGroupMetrics.map(a => a.questionGroup),
    datasets: [
      ...getDataSets(questionGroupMetrics),
      ...backgroundGroupDataSets
    ],
  };

  const questionGroupPolarAreaData = {
    labels: questionGroupMetrics.map(a => a.questionGroup),
    datasets: [
      ...getDataSets(questionMetrics),
    ],
  };

  const questionDoughnutData = {
    //labels: [ "0 - 2.9", "3 - 3.2", "3.3 - 3.4", "3.5 - 4" ],
    labels: [ "Strong Disagreement", "Moderate Disagreement", "Moderate Agreement", "Strong Agreement" ],
    datasets: [{
      data: [ questionColorCounts.red ?? 0, questionColorCounts.orange ?? 0, questionColorCounts.yellow ?? 0, questionColorCounts.green ?? 0 ],
      backgroundColor: [ colors.red, colors.orange, colors.yellow, colors.green  ]
    }]
  };

  const labelItemsPlugin = {
    id: "labelItems",
    afterDatasetsDraw(chart, args, pluginOptions) {
      const {ctx, data, scales: {r}} = chart;
      const xCenter = r.xCenter;
      const yCenter = r.yCenter;
      const labels = chart.config.data.labels;
      const pointLabelItems = chart.scales.r._pointLabelItems;
      labels.forEach((label, index) => {
        if(!label)
          return;

        const radius = pluginOptions.selectedGroup === label ? 25 : 22;

        const { top, right, bottom, left } = pointLabelItems[index];
        const x = right - ((right - left) / 2);
        const y = top < yCenter ? top - radius - 5 : bottom + radius + 5;

        pointLabelItems[index].circleTop = y - radius;
        pointLabelItems[index].circleRight = x + radius;
        pointLabelItems[index].circleBottom = y + radius;
        pointLabelItems[index].circleLeft = x - radius;

        const enabledLegendItems = chart.legend.legendItems.filter(a => !a.hidden).map(a => a.text);
        
        const questionGroupMetric = questionGroupMetrics.find(a => a.questionGroup === label);
        const groupAverage = enabledLegendItems.length === 1 ? round(questionGroupMetric.metricScores.find(a => a.name === enabledLegendItems[0]).average) : round(questionGroupMetric.metricScores.find(a => a.name === "All").average);
        
        ctx.save();
        ctx.translate(x, y);

        ctx.beginPath();
        ctx.arc(0, 0, radius, 0, 2 * Math.PI);
        ctx.fillStyle = getColorFromScore(groupAverage);
        if(pluginOptions.selectedGroup === label) {
          ctx.shadowBlur = 4;
          ctx.shadowColor = "gray";
        }
        ctx.fill();
        //ctx.strokeStyle = "#000000";
        ctx.textAlign = "center";
        ctx.fillStyle = "#545454";
        ctx.shadowBlur = 0;
        ctx.textBaseline = "middle";
        ctx.font = pluginOptions.selectedGroup === label ? "bold 14px Segoe UI" : "normal 12px Segoe UI";
        ctx.fillText(groupAverage.toFixed(1), 0, 0);
        ctx.restore();

        //angle lines
        if(pluginOptions.selectedGroup === label) {
          const labelIndex = labels.filter(a => a).findIndex(a => a === label);
          const acceptableIndexes = [ 0, 8, 17, 27, 33, 39, 44, 50, 56 ];
          const datasetMeta = chart.getDatasetMeta(0);
          const radius = r.drawingArea + 1;
          const startIndex = acceptableIndexes[labelIndex];
          const startAngle = datasetMeta.data[startIndex].angle;
          const endIndex = labelIndex === acceptableIndexes.length - 1 ? 0 : acceptableIndexes[labelIndex+1];
          const endAngle = datasetMeta.data[endIndex].angle;
          
          ctx.save();
          ctx.translate(xCenter, yCenter);
          ctx.beginPath();
          ctx.strokeStyle = "rgb(0, 0, 0, .1)";
          ctx.lineWidth = 2;
          ctx.shadowBlur = 8;
          ctx.shadowColor = "black";

          //const startCoordinates = [Math.cos(startAngle) * radius, Math.sin(startAngle) * radius];
          //const endCoordinates = [Math.cos(endAngle) * radius, Math.sin(endAngle) * radius];

          ctx.arc(0, 0, radius, startAngle, endAngle, false);
          ctx.stroke(); //first stroke of just the arc to make it darker

          ctx.strokeStyle = "rgb(0, 0, 0, .1)";
          ctx.lineTo(0,0);
          ctx.closePath();
          ctx.fillStyle = "rgb(255, 255, 255, .1)";
          ctx.fill();
          ctx.stroke();
          ctx.restore();
        }
      });
    }
  }

  if(loading)
    return (
      <div className="h-100 d-flex justify-content-center align-items-center">
        <span className="spinner-border text-primary" style={{width: "4rem", height: "4rem"}} role="status" />
      </div>
    )

  if(error)
    return (<p>Error: {error}</p>)

  let questionGroupAverage = 0;
  if(pageView !== "overall")
    questionGroupAverage = enabledLevels.length === 1 ? round(questionGroupMetric.metricScores.find(a => a.name === enabledLevels[0]).average) : round(questionGroupMetric.metricScores.find(a => a.name === "All").average);
  
  return (
    <Container fluid>
      <Row md={pageView === "overall" ? 12 : 2}>
        { pageView === "overall" &&
          <Col>
            <OverallMetrics
                isLeftSide
                questionMetrics={questionMetrics}
                questionGroupMetrics={questionGroupMetrics}
                questions={questions}
                enabledLevels={enabledLevels}
                showAverageScore={showAverageScore}
                showDegreeOfAlignment={showDegreeOfAlignment}
                getClassFromScore={getClassFromScore}
                getClassFromAlignment={getClassFromAlignment}
                onQuestionClick={onPointClick}
                onLabelClick={onLabelClick}
                toggleDegreeOfAlignment={() => setShowDegreeOfAlignment(!showDegreeOfAlignment)}
              />
          </Col>
        }
        <Col md={6}>
          <div className="chart-container">
            <RadarChart 
              chartData={questionRadarData} 
              plugins={[labelItemsPlugin]}
              onPointClick={onPointClick}
              onLabelClick={onLabelClick}
              setEnabledLegendItems={setEnabledLegendItems}
              getLineWidth={getLineWidth}
              getPointLabelFont={getPointLabelFont}
              selectedGroup={questionGroupMetric ? questionGroupMetric.questionGroup : null}
            />
          </div>
        </Col>
          <Col className="mx-auto d-flex flex-column">
            <div className="w-100 mt-2">
              { pageView !== "overall" && (
                <div className="w-100 d-flex">
                  <div className="w-100 d-flex flex-fill justify-content-evenly mb-3">
                    <div className="align-center justify-content-evenly">
                      <h2 className="text-center fw-normal">{clientName}</h2>
                      <h3 className="text-center">{questionGroupMetric.questionGroup}</h3>
                    </div>
                    <div className="circle-container">
                        <div className={`circle ${getClassFromScore(questionGroupAverage)}`}>
                            <div>{questionGroupAverage.toFixed(1)}</div>
                        </div>
                    </div>
                  </div>
                  <div className="justify-content-end">
                    <Form.Check 
                      className="text-end"
                      type="switch" 
                      label="OAI"
                      checked={showAverageScore} 
                      onChange={(event) => setShowAverageScore(event.target.checked)} 
                    />
                    <Form.Check 
                      type="switch" 
                      label="DOA"
                      checked={showDegreeOfAlignment} 
                      onChange={(event) => setShowDegreeOfAlignment(event.target.checked)} 
                    />
                  </div>
                </div>
              )}
              { pageView === "group" &&
                <GroupMetrics
                  questionGroupMetric={questionGroupMetric}
                  questionMetrics={questionMetrics}
                  questions={questions}
                  enabledLevels={enabledLevels}
                  questionColorCounts={questionColorCounts}
                  questionAlignmentColorCounts={questionAlignmentColorCounts}
                  showAverageScore={showAverageScore}
                  showDegreeOfAlignment={showDegreeOfAlignment}
                  getClassFromScore={getClassFromScore}
                  getClassFromAlignment={getClassFromAlignment}
                  onQuestionClick={onPointClick}
                  setPageView={changePageView}
                  colors={colors}
                />
              }
              { pageView === "overall" &&
                <OverallMetrics 
                  questionMetrics={questionMetrics.filter(a => a.questionId < 38)}
                  questionGroupMetrics={questionGroupMetrics}
                  questions={questions}
                  enabledLevels={enabledLevels}
                  showAverageScore={showAverageScore}
                  showDegreeOfAlignment={showDegreeOfAlignment}
                  getClassFromScore={getClassFromScore}
                  getClassFromAlignment={getClassFromAlignment}
                  onQuestionClick={onPointClick}
                  onLabelClick={onLabelClick}
                  toggleDegreeOfAlignment={() => setShowDegreeOfAlignment(!showDegreeOfAlignment)}
                />
              }
              { pageView === "question" &&
                <QuestionMetrics
                  questionTitle={questionTitle}
                  questionMetric={questionMetric}
                  question={questions.find(a => a.questionId == questionMetric.questionId)}
                  questionColorCounts={questionColorCounts}
                  enabledLevels={enabledLevels}
                  showAverageScore={showAverageScore}
                  showDegreeOfAlignment={showDegreeOfAlignment}
                  getClassFromScore={getClassFromScore}
                  getClassFromAlignment={getClassFromAlignment}
                  getColorFromAlignmentRank={getColorFromAlignmentRank}
                  setPageView={changePageView}
                />
              }
              { pageView !== "overall" && commentMetric && commentMetric.comments.length > 0 && (
                <div>
                  <a className="comment-button" onClick={onCommentsClick}>
                    <span><FontAwesomeIcon icon={faComments} size="2x" /></span>
                  </a>
                  <Offcanvas show={showComments} onHide={() => setShowComments(false)} placement="end" className="w-50">
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>{commentMetric.questionGroup} Comments</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <p className="fw-bold">{commentMetric.questionName} {commentQuestion && commentQuestion.title}</p>
                      {/* <Accordion defaultActiveKey="0">
                        { commentMetric.comments.map((comment, index) => (
                            <Accordion.Item key={index} eventKey={index}>
                              <Accordion.Header>
                                <p className="ellipsis align-middle w-75 mt-0 mb-0 mr-2">{comment}</p>
                              </Accordion.Header>
                              <Accordion.Body>{comment}</Accordion.Body>
                            </Accordion.Item>
                        ))}
                      </Accordion> */}

                      <ListGroup>
                        { commentMetric.comments.filter(a => a).map((comment, index) => (
                          <ListGroupItemEllipsis key={index} text={comment} enabledByDefault={false} />
                        ))}
                      </ListGroup>
                    </Offcanvas.Body>
                  </Offcanvas>
                </div>
              )}
              { pageView !== "overall" && (
                <div className="mt-5 tile-container">
                  <span className="p-2 text-center-middle">Not Aligned</span>
                  <span className="p-4 bg-red" />
                  <span className="p-4 bg-redOrange" />
                  <span className="p-4 bg-orange" />
                  <span className="p-4 bg-orangeYellow" />
                  <span className="p-4 bg-yellow" />
                  <span className="p-4 bg-yellowGreen" />
                  <span className="p-4 bg-green" />
                  <span className="p-4 bg-lightGreen" />
                  <span className="p-2 text-center-middle">Aligned</span>
                </div>
              )}
            </div>
          </Col>
      </Row>
    </Container>
  );
}