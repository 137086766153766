import React from "react";
import { ArcElement, BarElement, CategoryScale, Chart, Filler, Legend, LineElement, LinearScale, PointElement, RadialLinearScale, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { getChartLabelPlugin } from 'chart.js-plugin-labels-dv';

Chart.register(getChartLabelPlugin());

const options = {
    responsive: true,
    maintainAspectRatio: false,
    rotation: 270,
    //circumference: 180,
    cutout: "60%",
    layout: {
        padding: {
            top: 50,
            bottom: 50,
        }
    },
    plugins: {
        legend: {
            display: false,
        },
        labels: [
            {
                render: 'label',
                position: 'outside',
                fontSize: 18,
                arc: true
            },
            {
                render: 'value',
                precision: 1,
                fontSize: 30,
                fontColor: '#f5f5f5',
                arc: true
            }
        ]
    }
}

export function DoughnutChart({ chartData }) {
    return (
        <Doughnut
            data={chartData}
            options={options}
        />
    );
}