import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

export function MultiTextInput(props) {
  const placeholder = props.placeholder ?? "Type something and press enter...";
  const options = props.options ?? undefined;
  const className = props.className ?? undefined;
  const [inputValue, setInputValue] = useState('');
  //const [value, setValue] = useState([]);

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if(!props.value.find(a => a.label === inputValue)) {
            props.setValue((prev) => [...prev, createOption(inputValue)]);
        }
        setInputValue('');
        event.preventDefault();
    }
  };

  if(props.creatable) {
    return (
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        required={props.required}
        menuIsOpen={false}
        onChange={(newValue) => props.setValue(newValue)}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={props.value}
        options={options}
        className={className}
      />
    );
  } else {
    return (
      <Select
        value={props.value}
        onChange={(newValue) => props.setValue(newValue)}
        isMulti
        isClearable
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder={placeholder}
        options={options}
      />
    );
  }
};