import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import { Button, OverlayTrigger, Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AlignmentTooltip from "./controls/AlignmentTooltip";
import { DoughnutChart } from "./charts/DoughnutChart";
import { BarChart } from "./charts/BarChart";
import { GenericTooltip } from "./controls/AlignmentTooltip";

const round = (value) => Math.round(value * 10) / 10;

export default function GroupMetrics(props) {
    const questionGroupMetric = props.questionGroupMetric;
    const groupQuestionMetrics = props.questionMetrics.filter(a => a.questionGroup === questionGroupMetric.questionGroup);
    const getClassFromScore = props.getClassFromScore;
    const getClassFromAlignment = props.getClassFromAlignment;
    const onQuestionClick = props.onQuestionClick;
    const questions = props.questions;
    const enabledLevels = props.enabledLevels;
    const questionColorCounts = props.questionColorCounts;
    const questionAlignmentColorCounts = props.questionAlignmentColorCounts;
    const colors = props.colors;

    const questionDoughnutData = {
        //labels: [ "0 - 2.9", "3 - 3.2", "3.3 - 3.4", "3.5 - 4" ],
        labels: [ ["Strong", "Disagreement"], ["Moderate", "Disagreement"], ["Some", "Disagreement"], ["Little", "Disagreement"], ["Little", "Agreement"], ["Some", "Agreement"], ["Moderate", "Agreement"], ["Strong", "Agreement"] ],
        datasets: [{
            data: [ questionColorCounts.red, questionColorCounts.redOrange, questionColorCounts.orange, questionColorCounts.orangeYellow, questionColorCounts.yellow, questionColorCounts.yellowGreen, questionColorCounts.green, questionColorCounts.lightGreen ],
            backgroundColor: [ colors.red, colors.redOrange, colors.orange, colors.orangeYellow, colors.yellow, colors.yellowGreen, colors.green, colors.lightGreen ]
        }]
    };

    const questionAlignmentDoughnutData = {
        //labels: [ "0 - 2.9", "3 - 3.2", "3.3 - 3.4", "3.5 - 4" ],
        labels: [ ["Strong", "Disagreement"], ["Moderate", "Disagreement"], ["Some", "Disagreement"], ["Little", "Disagreement"], ["Little", "Agreement"], ["Some", "Agreement"], ["Moderate", "Agreement"], ["Strong", "Agreement"] ],
        datasets: [{
            data: [ questionAlignmentColorCounts.red, questionAlignmentColorCounts.redOrange, questionAlignmentColorCounts.orange, questionAlignmentColorCounts.orangeYellow, questionAlignmentColorCounts.yellow, questionAlignmentColorCounts.yellowGreen, questionAlignmentColorCounts.green, questionAlignmentColorCounts.lightGreen ],
            backgroundColor: [ colors.red, colors.redOrange, colors.orange, colors.orangeYellow, colors.yellow, colors.yellowGreen, colors.green, colors.lightGreen ]
        }]
    };

    return (
        <div>
            {/* <Button variant="link" className="p-0 text-decoration-none" onClick={() => props.setPageView("overall")}><FontAwesomeIcon icon={faArrowLeft} /> Back to Overview</Button> */}
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => props.setPageView("overall")}>Overview</Breadcrumb.Item>
                <Breadcrumb.Item active>{questionGroupMetric.questionGroup}</Breadcrumb.Item>
            </Breadcrumb>
            <Table bordered hover size="sm">
                <thead>
                    <tr>
                        <th rowSpan="2" className="text-center-middle">#</th>
                        <th rowSpan="2" className="align-middle">Question</th>
                        { enabledLevels.map((level, index) => {
                            return (
                                <th key={index} colSpan={props.showAverageScore && props.showDegreeOfAlignment ? 2 : 1} className="text-center-middle">{level}</th>
                            );
                        })}
                    </tr>
                    <tr className="row-text-center-middle">
                        { enabledLevels.map((level, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {props.showAverageScore && (
                                        <OverlayTrigger placement="top" delay={{ show: 100, hide: 100 }} overlay={GenericTooltip({ description: "Organization Alignment Index" })}>
                                            <th>OAI</th>
                                        </OverlayTrigger>
                                    )}
                                    {props.showDegreeOfAlignment && (
                                        <OverlayTrigger placement="top" delay={{ show: 100, hide: 100 }} overlay={GenericTooltip({ description: "Degree Of Alignment" })}>
                                            <th>DOA</th>
                                        </OverlayTrigger>
                                    )}
                                </React.Fragment>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    { groupQuestionMetrics.map((questionMetric) => {
                        const questionIndex = props.questionMetrics.findIndex(a => a.questionId === questionMetric.questionId);
                        return (
                            <tr key={questionMetric.questionId} className="clickable" onClick={() => onQuestionClick(questionIndex)}>
                                <td className="text-center-middle">{questionMetric.questionName}</td>
                                <td className="align-middle">{questions[questionIndex].shortTitle}</td>

                                { enabledLevels.map((level, index) => {
                                    const metricScore = questionMetric.metricScores.find(a => a.name === level);
                                    const average = round(metricScore.average);
                                    const alignmentIndex = round(metricScore.alignmentIndex);
                                    return (
                                        <React.Fragment key={index}>
                                            { props.showAverageScore && <td className={`number-cell text-center-middle ${getClassFromScore(average)}`}>{average.toFixed(1)}</td> }
                                            {/* { props.showDegreeOfAlignment && <td className={`text-center-middle fg-white ${getClassFromAlignment(alignmentIndex)}`}>{alignmentIndex}</td> } */}
                                            {/* { props.showDegreeOfAlignment && <td className={`text-center-middle ${getClassFromAlignment(alignmentIndex)}`}></td> } */}
                                            { props.showDegreeOfAlignment && <td className={`number-cell text-center-middle ${getClassFromAlignment(alignmentIndex)}`} /> }
                                            {/* { props.showDegreeOfAlignment && 
                                                <OverlayTrigger placement="top" delay={{ show: 100, hide: 100}} overlay={AlignmentTooltip({alignmentIndex})}>
                                                    <td className={`text-center-middle ${getClassFromAlignment(alignmentIndex)}`} />
                                                </OverlayTrigger>
                                            } */}
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
}