import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faCircleDot } from '@fortawesome/free-solid-svg-icons/faCircleDot';
import { MultiTextCellEditor, RequiredCellEditor, AgGridMultiSelectEditor } from './grid/CellEditors';
import { CustomHeader } from "./grid/CustomHeader";

const ActionComponent = (props) => {
    if(props === undefined)
        return null;
    const value = props.value;
    if(value === undefined)
        return null;
    if(props.data.currentSurveyStatus === "Completed")
        return null;
    return <Button variant="outline-primary" onClick={() => props.handleSendSurveyToEmployee(value)}>Send Survey</Button>
    //const surveyUrl = "https://localhost:3000/Survey/" + encodeURIComponent(`?clientId=${props.clientId}&surveyId=${props.surveyId}&employeeId=${value}`);
    //return <a className="btn btn-outline-primary" href={`mailto:${props.data.email}?subject=AlignOrg Survey&body=Please click this link to take the survey: ${surveyUrl}`}>Send Survey</a>
}

const StatusComponent = (props) => {
    if(props === undefined)
        return null;
    const value = props.value;
    if(value === undefined)
        return null;
    if(value === "Not Started")
        return <span><FontAwesomeIcon className="fg-red" icon={faCircleExclamation} /> {value}</span>
    if(value === "Completed")
        return <span><FontAwesomeIcon className="fg-green" icon={faCircleCheck} /> {value}</span>

    return <span><FontAwesomeIcon className="fg-blue" icon={faCircleDot} /> {value}</span>
}

const managementLevels = [
    "L0",
    "L1",
    "L2",
    "L3+",
];

const functionOptions = [
    { value: "Commercial", label: "Commercial" },
    { value: "Customer Service", label: "Customer Service" },
    { value: "Enterprise", label: "Enterprise" },
    { value: "Finance", label: "Finance" },
    { value: "Human Resources", label: "Human Resources" },
    { value: "Information Technology", label: "Information Technology" },
    { value: "Legal", label: "Legal" },
    { value: "Marketing", label: "Marketing" },
    { value: "Operations", label: "Operations" },
    { value: "Procurement", label: "Procurement" },
    { value: "Research and Development", label: "Research and Development" },
    { value: "Sales", label: "Sales" },
];

export function EmployeeGrid(props) {
    const columnDefs = [
        { field: "name", filter: true, cellEditor: RequiredCellEditor },
        { field: "email", cellEditor: RequiredCellEditor, cellEditorParams: { regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ }, },
        { field: "title", cellEditor: RequiredCellEditor },
        { 
            field: "managementLevel",
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: managementLevels,
            },
        },
        { 
            field: "functions",
            valueFormatter: p => p.value.join(" | "),
            // valueParser: params => {
            //     const newValue = params.newValue;
            //     if(newValue.includes(","))
            //         return newValue.split(",").map(a => a.trim());
            //     if(newValue.includes("|"))
            //         return newValue.split("|").map(a => { label: a.trim(); value: a.trim() });
            //     return [ newValue ];
            // },
            // cellEditor: MultiTextCellEditor,
            // cellEditorParams: {
            //     options: functionOptions
            // },
            cellEditorPopup: true,
            cellEditor: AgGridMultiSelectEditor,
            cellEditorParams: {
                options: functionOptions,
            },
            // valueSetter: (params) => { 
            //     console.log(params);
            //     console.log("functions", params.data.functions);
            //     console.log("new", params.newValue);
            //     if(!params.newValue)
            //         params.data.functions = [];
            //     else
            //         params.data.functions = params.newValue.split(",");
            //     return true;
            // } 
        },
        { field: "currentSurveyStatus", editable: false, cellRenderer: StatusComponent },
        { 
            field: "id", 
            headerName: "",
            headerComponent: CustomHeader,
            headerComponentParams : {
                handleSendSurveyToAll: props.handleSendSurveyToAll,
            },
            editable: false,
            cellClass: "justify-content-center", 
            cellRenderer: ActionComponent, 
            cellRendererParams: { 
                handleSendSurveyToEmployee: props.handleSendSurveyToEmployee,
                clientId: props.clientId,
                surveyId: props.surveyId
            } 
        }
    ];

    const onRowValueChanged = useCallback((event) => {
        const data = event.data;
        console.log('event', event);
        console.log('onRowValueChanged: (' +
            data.name +
            ', ' +
            data.email +
            ', ' +
            data.managementLevel +
            ', ' +
            data.functions +
            ', ' +
            data.currentSurveyStatus +
        ')');
        console.log(data);
    }, []);

    return (
        <div className="ag-theme-quartz flex-content-grow">
            <AgGridReact 
                rowData={props.rowData}
                columnDefs={columnDefs}
                defaultColDef={ { editable: false, flex: 1 } }
                rowHeight={ 50 }
                editType={"fullRow"}
                onRowValueChanged={onRowValueChanged}
                singleClickEdit={false}
                pagination={true}
                paginationAutoPageSize={true}
                style={{ width: "100%", height: "100%", minHeight: "205px"}}
            />
        </div>
    );
}