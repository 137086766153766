import React from 'react';
import Button from 'react-bootstrap/Button';

export function CustomHeader ( props ) {
    return (
        <div className="custom-header">
            <Button variant="outline-primary" onClick={() => props.handleSendSurveyToAll()}>Send Survey to All</Button>
        </div>
    );
}
