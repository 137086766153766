import { React, useRef } from "react";
import { Radar, getElementsAtEvent } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';
import { getChartLabelPlugin } from 'chart.js-plugin-labels-dv';

Chart.register(getChartLabelPlugin());

export function RadarChart({ chartData, plugins, onPointClick, onLabelClick, setEnabledLegendItems, getLineWidth, getPointLabelFont, selectedGroup }) {

    const chartRef = useRef();

    const getLabelIndex = (x, y, pointLabels) => {
        let index = -1;
        for (let i = 0; i < pointLabels.length - 1; i++) {
            const { top, right, bottom, left, circleTop, circleRight, circleBottom, circleLeft } = pointLabels[i];
            if(x >= left && x <= right && y >= top && y <= bottom) {
                index = i;
                break;
            }
            if(circleTop && x >= circleLeft && x <= circleRight && y >= circleTop && y <= circleBottom) {
                index = i;
                break;
            }
        }
        return index;
    };

    const getSectionLabel = (x, y, radius, labels, datasetMeta) => {
        const acceptableIndexes = [ 0, 8, 17, 27, 33, 39, 44, 50, 56 ];
        const acceptableLabels = labels.filter(a => a);

        for (let i = 0; i < acceptableLabels.length; i++) {
            const label = acceptableLabels[i];
            const labelIndex = labels.filter(a => a).findIndex(a => a === label);

            const startIndex = acceptableIndexes[labelIndex];
            const startAngle = datasetMeta.data[startIndex].angle;
            const endIndex = labelIndex === acceptableIndexes.length - 1 ? 0 : acceptableIndexes[labelIndex+1];
            let endAngle = datasetMeta.data[endIndex].angle;

            if(labelIndex === acceptableIndexes.length - 1)
                endAngle = 5;

            let polarRadius = Math.sqrt(x * x + y * y);
            let angle = Math.atan(y / x);
            if(x < 0)
                angle = angle + Math.PI;
            if(angle >= startAngle && angle <= endAngle && polarRadius < radius) {
                //console.log(x, y, angle, startAngle, endAngle, polarRadius, radius, label);
                return label;
            }
        }
        return null;
    }

    const onClick = (event) => {
        const chart = Chart.getChart(chartRef.current);
        const r = chart.scales.r;
        const clickX = event.nativeEvent.offsetX;
        const clickY = event.nativeEvent.offsetY;

        //handle fill click
        //const data = chart.scales.r;

        //handle point label click
        const idx = getLabelIndex(clickX, clickY, r._pointLabelItems);
        const group = r._pointLabels[idx];
        if(onLabelClick && group && group.length > 0) {
            onLabelClick(group);
            event.preventDefault();
            return;
        }
        
        //handle point click
        const elements = getElementsAtEvent(chartRef.current, event);
        if(elements.length !== 0) {
            const index = elements[0].index;
            if(onPointClick)
                onPointClick(index);
            event.preventDefault();
            return;
        }

        //Check for section
        const xCenter = r.xCenter;
        const yCenter = r.yCenter;
        let x = clickX - xCenter;
        let y = clickY - yCenter;
        const datasetMeta = chart.getDatasetMeta(0);
        const sectionLabel = getSectionLabel(x, y, r.drawingArea, chart.config.data.labels, datasetMeta);
        if(sectionLabel)
            onLabelClick(sectionLabel);
        event.preventDefault();
    }

    const onLegendClick = (event, legendItem, legend) => {
        const index = legendItem.datasetIndex;
        const chart = legend.chart;
        if (chart.isDatasetVisible(index)) {
            chart.hide(index);
            legendItem.hidden = true;
        } else {
            chart.show(index);
            legendItem.hidden = false;
        }
        setEnabledLegendItems(legend.legendItems.filter(a => !a.hidden).map(a => a.text));
    }

    const onHover = (event, chartElement) => {
        //Check for point
        if(chartElement.length == 1 && chartElement[0].element.constructor.name === "PointElement") {
            event.native.target.style.cursor = "pointer"
            return;
        }

        //Check for point label
        const chart = Chart.getChart(chartRef.current);
        const r = chart.scales.r;
        const hoverX = event.native.offsetX;
        const hoverY = event.native.offsetY;
        const idx = getLabelIndex(hoverX, hoverY, r._pointLabelItems);
        if(idx !== -1) {
            event.native.target.style.cursor = "pointer"
            return;
        }

        //Check for section
        const xCenter = r.xCenter;
        const yCenter = r.yCenter;
        let x = hoverX - xCenter;
        let y = hoverY - yCenter;
        const datasetMeta = chart.getDatasetMeta(0);
        const sectionLabel = getSectionLabel(x, y, r.drawingArea, chart.config.data.labels, datasetMeta);
        if(sectionLabel !== null) {
            event.native.target.style.cursor = "pointer";
            return;
        }
        event.native.target.style.cursor = "default"
    }
    
    const options = {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            arc: {
                circular: true
            }
        },
        scales: {
            r: {
                align: 'end',
                startAngle: 0,
                min: 1, //2.2, //0 MIN
                max: 5, //3.6, //5 MAX
                beginAtZero: false,
                ticks: {
                    display: true,
                    color: "rgba(0, 0, 0, .5)",
                    stepSize: 1,
                    z: 1,
                    showLabelBackdrop: false,
                },
                grid: {
                    display: false,
                    z: 1,
                    circular: true,
                },
                angleLines: {
                    display: true,
                    color: "rgba(0, 0, 0, .1)",
                    lineWidth: getLineWidth ?? 1,
                },
                pointLabels: {
                    display: true,
                    backdropColor: null,
                    backdropPadding: 0,
                    padding: 0,
                    arc: true,
                    centerPointLabels: true,
                    callback: (item) => { 
                        if(item)
                            return item;
                        return undefined;
                     },
                    color: "#545454",
                    font: getPointLabelFont,
                    width: "10px"
                    // {
                    //     size: 12,
                    // }
                },
                interaction: {
                    intersect: false
                }
            },
        },
        tooltips: {
            filter: function(tooltipItem) {
                return tooltipItem.dataset.pointRadius > 0; //return true if showing point
            },
            callbacks: {
                title: (tooltipItem, data) => {
                    return data.labels[tooltipItem[0].index];
                },
                //label: () => "test",
                //     console.log(tooltipItem);
                //     return tooltipItem.label + "?";
                //     return Math.round(tooltipItem.dataset.parsed.r * 10) / 10;
                // }
            }
        },
        plugins: {
            datalabels: {
                display: false,
                align: 'start',
                anchor: 'end',
                arc: true
            },
            labels: {
                display: false,
                render: 'label',
                precision: 1,
                position: "outside",
                arc: true,
            },
            legend: {
                display: true,
                labels: {
                    // generateLabels: (chart) => {
                    //     console.log("generateLabels");
                    // },
                    filter: function(legendItem, data) {
                        return legendItem.text; //return true if text has a value
                    }
                },
                onClick: onLegendClick,
                onHover: function(event) {
                    event.native.target.style.cursor = 'pointer';
                }
            },
            labelItems: { 
                selectedGroup: selectedGroup
            }
        },
        onHover: onHover
        //onClick: onClick
    }


    return (
        <Radar
            data={chartData}
            options={options}
            plugins={plugins}
            onClick={onClick}
            ref={chartRef}
        />
    );
}