import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from '../components/DataTable';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faArrowUpRightFromSquare';
import { DateCellRenderer, LinkCellRenderer } from '../components/grid/CellRenderers';
//import { clients } from "../data/clients.js";

const columns = [
  { data: "name", title: "Name" },
  { data: "createdDate", title: "Created Date", type: 'date' },
  // { title: "Actions", type: 'html', defaultContent: "<button class='btn btn-secondary'>Edit</button>"  }
]

const employeeComponent = (props) => {
  return (
  <Link className="btn-primary" to={`/Clients/${props.value}`}>
    <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="lg" />
  </Link>
  )
}



export function HomePage() {

  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
      getClients();
  }, []);

  const getClients = async () => {
    try {
      if(clients && clients.length > 0)
        return;
      const response = await fetch(`${process.env.REACT_APP_API_URL}Clients`);
      if(!response.ok) {
        throw new Error('Could not retrieve clients.');
      }
      const data = await response.json();
      setClients(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }
  
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleNameChange = (event) => { setName(event.target.value); };
  const handleSave = async (event) => {
    event.preventDefault();
    setShow(false);

    //check for existing client
    if(clients.find(a => a.name === name))
      throw new Error(`Could not add client. ${name} already exists.`);
    
    try {
      var params = { 
        method: "POST",
        body: JSON.stringify({
          name,
          createdDate: new Date(),
          employees: []
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        }
      };
      const response = await fetch(`${process.env.REACT_APP_API_URL}Clients`, params);
      if(!response.ok) {
        throw new Error('Could not add Client.');
      }
      const data = await response.json();
      setClients((existingClients) => [ ...existingClients, data ]);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onClientClick = (client) => {
    
  };

  const columnDefs = [
    { field: "name", filter: true, flex: 1, cellRenderer: LinkCellRenderer, cellRendererParams: { onClick: onClientClick } },
    { field: "createdDate", headerName: "Created Date", cellDataType: 'date', filter: true, flex: 1, cellRenderer: DateCellRenderer, sort: 'desc' },
    { field: "id", headerName: "", cellClass: "justify-content-center", flex: .5, cellRenderer: employeeComponent }
  ]

  const dataTableConfig = {
    data: clients,
    columns: columns,
    // columnDefs: [
    //   null,
    //   null,
    //   {type: 'date'},
    //   {
    //     data: null,
    //     defaultContent: '<button>Edit</button>',
    //     targets: -1
    //   }
    // ],
    order: {
      name: 'CreatedDate',
      dir: 'desc'
    },
    ordering: true,
    searching: false,
    paging: false,
    select: true,
  }

  return (
    <div className="container-fluid px-5 flex-box">
      <h2>Clients</h2>
      {
        loading ? (
          <div className="h-100 d-flex justify-content-center align-items-center">
            <span className="spinner-border text-primary" style={{width: "4rem", height: "4rem"}} role="status" />
          </div>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          
          /* <DataTable config={dataTableConfig} /> */
          
          <div className="ag-theme-quartz flex-content-grow">
            <AgGridReact 
              rowData={clients} 
              columnDefs={columnDefs}
              rowHeight={ 50 }
              pagination={true}
              paginationAutoPageSize={true}
              style={{ width: "100%", height: "100%", minHeight: "205px"}}
            />
          </div>
        )
      }

      <div className="flex-content-auto mb-3">
        <Button variant="primary" className="mt-3" onClick={handleShow}>
          Add Client
        </Button>
      </div>

      <Modal variant="large" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Client</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSave}>
          <Modal.Body>
          <Form.Group>
                <Form.Label>Company Name: </Form.Label>
                <Form.Control type="text" required onChange={handleNameChange} placeholder="Company Name"/>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* <button type="button" className="btn btn-primary mt-3" data-toggle='modal' data-target="#addClientModal">Add Client</button>

      <div id="addClientModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Client</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            ...
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary">Save</button>
          </div>
        </div>
      </div> */}
    </div>
  );
}
