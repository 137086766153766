import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { Route } from 'react-router-dom';


const loading = () => {
    return (
        <div className="h-100 d-flex justify-content-center align-items-center">
        <span className="spinner-border text-primary" style={{width: "4rem", height: "4rem"}} role="status" />
        </div>
    );
}

export const ProtectedComponent = ({ children }) => {
    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            loadingComponent={loading}
        >
            {children}
        </MsalAuthenticationTemplate>
    );
};