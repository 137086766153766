import Tooltip from 'react-bootstrap/Tooltip';

function getDescriptionFromAlignment(alignment) {
    if (alignment <= .25)
        return "Strong Disagreement";
      if (alignment > .25 && alignment <= .5)
        return "Moderate Disagreement";
      if (alignment > .5 && alignment <= .75)
        return "Some Disagreement";
      if (alignment > .75 && alignment <= 1)
        return "Little Disagreement";
      if (alignment > 1 && alignment <= 1.25)
        return "Little Agreement";
      if (alignment > 1.25 && alignment <= 1.5)
        return "Some Agreement";
      if (alignment > 1.5 && alignment <= 1.75)
        return "Moderate Agreement";
      if (alignment > 1.75)
        return "Strong Agreement";
};

export default function AlignmentTooltip(props) {
    const description = getDescriptionFromAlignment(props.alignmentIndex);

    return (
        <Tooltip style={{position:"fixed"}}>{description}</Tooltip>
    );
}

export function GenericTooltip(props) {
  return (
    <Tooltip style={{position:"fixed"}}>{props.description}</Tooltip>
  );
}