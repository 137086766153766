export const summarizedSurveyResultData = [
    {
      "questionGroup": "Continuous Improvement",
      "average": 2.794736842,
      "alignmentRank": 2.773684211
    },
    {
      "questionGroup": "Guiding Transformation",
      "average": 2.913157895,
      "alignmentRank": 3.002631579
    },
    {
      "questionGroup": "Information & Metrics",
      "average": 2.912280702,
      "alignmentRank": 2.811403509
    },
    {
      "questionGroup": "Leadership & Culture",
      "average": 3.144736842,
      "alignmentRank": 3.162280702
    },
    {
      "questionGroup": "Organization & Alignment",
      "average": 3.021929825,
      "alignmentRank": 3.065789474
    },
    {
      "questionGroup": "People & Rewards",
      "average": 2.75,
      "alignmentRank": 2.947368421
    },
    {
      "questionGroup": "Strategy",
      "average": 3.042763158,
      "alignmentRank": 3.006578947
    },
    {
      "questionGroup": "Structure & Governance",
      "average": 2.878947368,
      "alignmentRank": 2.884210526
    },
    {
      "questionGroup": "Work Process",
      "average": 2.877192982,
      "alignmentRank": 2.929824561
    }
   ];