import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";


export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };
  return (
    <Button  onClick={() => handleLogin("redirect")}>
      Sign In
    </Button>
    // <DropdownButton
    //   variant="secondary"
    //   className="ml-auto"
    //   drop="start"
    //   title="Sign In"
    // >
    //   <Dropdown.Item as="button" onClick={() => handleLogin("popup")}>
    //     Sign in using Popup
    //   </Dropdown.Item>
    //   <Dropdown.Item as="button" onClick={() => handleLogin("redirect")}>
    //     Sign in using Redirect
    //   </Dropdown.Item>
    // </DropdownButton>
  );
}; //color: #192957