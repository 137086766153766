import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import { Button, OverlayTrigger, Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRight, faArrowUp, faArrowLeft, faTheaterMasks, faThumbsDown, faThumbsUp, faFaceSmile, faFaceFrown, faFaceFrownOpen, faFaceGrimace, faFaceGrin, faFaceMeh } from "@fortawesome/free-solid-svg-icons";
import { faFaceDizzy } from "@fortawesome/free-regular-svg-icons";
import AlignmentTooltip, { GenericTooltip } from "./controls/AlignmentTooltip";

function getDescriptionFromAlignment(alignment) {
    if (alignment <= 2.9)
        return "Strong Disagreement";
    if (alignment > 2.9 && alignment <= 3.2)
        return "Moderate Disagreement";
    if (alignment > 3.2 && alignment <= 3.4)
        return "Moderate Agreement";
    if (alignment > 3.4)
        return "Strong Agreement";
};

function getIconFromAlignment(alignment) {
    if (alignment <= 2.9)
        return faFaceFrown;
    if (alignment > 2.9 && alignment <= 3.2)
        return faFaceMeh;
    if (alignment > 3.2 && alignment <= 3.4)
        return faFaceSmile;
    if (alignment > 3.4)
        return faFaceGrin;
};

const round = (value) => Math.round(value * 10) / 10;

export default function QuestionMetrics(props) {
    const questionTitle = props.questionTitle;
    const questionMetric = props.questionMetric;
    const question = props.question;
    const questionColorCounts = props.questionColorCounts;
    const getClassFromScore = props.getClassFromScore;
    const getClassFromAlignment = props.getClassFromAlignment;
    const enabledLevels = props.enabledLevels;
    const effectiveLevel = enabledLevels.length === 1 ? enabledLevels[0] : "All";
    
    const questionCounts = questionMetric.metricScores.find(a => a.name === effectiveLevel).responses.reduce((response, value) => {
        response[value] = (response[value] ?? 0) + 1;
        return response;
    }, {});

    return (
        <div>
            {questionMetric &&
                <div>
                    {/* <Button variant="link" className="p-0 text-decoration-none" onClick={() => props.setPageView("group")}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Back to {questionMetric.questionGroup}
                    </Button> */}
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => props.setPageView("overall")}>Overview</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => props.setPageView("group")}>{questionMetric.questionGroup}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{questionMetric.questionName}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th rowSpan="2" className="text-center-middle">#</th>
                                <th rowSpan="2" className="align-middle">Question</th>
                                {enabledLevels.map((level, index) => {
                                    return (
                                        <th key={index} colSpan={props.showAverageScore && props.showDegreeOfAlignment ? 2 : 1} className="text-center-middle">{level}</th>
                                    );
                                })}
                            </tr>
                            <tr className="row-text-center-middle">
                                {enabledLevels.map((level, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {props.showAverageScore && (
                                                <OverlayTrigger placement="top" delay={{ show: 100, hide: 100 }} overlay={GenericTooltip({ description: "Organization Alignment Index" })}>
                                                    <th>OAI</th>
                                                </OverlayTrigger>
                                            )}
                                            {props.showDegreeOfAlignment && (
                                                <OverlayTrigger placement="top" delay={{ show: 100, hide: 100 }} overlay={GenericTooltip({ description: "Degree Of Alignment" })}>
                                                    <th>DOA</th>
                                                </OverlayTrigger>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-center-middle">{questionMetric.questionName}</td>
                                <td className="align-middle">{question.shortTitle}</td>
                                {enabledLevels.map((level, index) => {
                                    const metricScore = questionMetric.metricScores.find(a => a.name === level);
                                    const average = round(metricScore.average);
                                    const alignmentIndex = round(metricScore.alignmentIndex);
                                    return (
                                        <React.Fragment key={index}>
                                            {props.showAverageScore && <td className={`text-center-middle number-cell ${getClassFromScore(average)}`}>{average.toFixed(1)}</td>}
                                            {props.showDegreeOfAlignment && <td className={`text-center-middle number-cell ${getClassFromAlignment(alignmentIndex)}`} /> }
                                            {/* {props.showDegreeOfAlignment &&
                                                <OverlayTrigger placement="top" delay={{ show: 100, hide: 100 }} overlay={AlignmentTooltip({ alignmentIndex })}>
                                                    <td className={`text-center-middle ${getClassFromAlignment(alignmentIndex)}`}>
                                                    </td>
                                                </OverlayTrigger>
                                            } */}
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                        </tbody>
                    </Table>
                    <div className="mt-3">
                        <p className="text-wrap">{question.title}</p>
                    </div>

                    <h5 className="text-center">Response Frequency ({effectiveLevel})</h5>
                    <div className="tile-container">
                        <div className="tile bg-red d-flex flex-column align-content-stretch">
                            <div style={{ height: "50px" }} className="d-flex align-items-center justify-content-center">
                                <h5>1</h5>
                            </div>
                            <span className="mt-auto">{questionCounts[1] ?? 0}</span>
                        </div>
                        <div className="tile bg-orange d-flex flex-column">
                            <div style={{ height: "48px" }} className="d-flex align-items-center justify-content-center">
                                <h5>2</h5>
                            </div>
                            <span className="mt-auto">{questionCounts[2] ?? 0}</span>
                        </div>
                        <div className="tile bg-yellow d-flex flex-column">
                            <div style={{ height: "48px" }} className="d-flex align-items-center justify-content-center">
                                <h5>3</h5>
                            </div>
                            <span className="mt-auto">{questionCounts[3] ?? 0}</span>
                        </div>
                        <div className="tile bg-green d-flex flex-column">
                            <div style={{ height: "48px" }} className="d-flex align-items-center justify-content-center">
                                <h5>4</h5>
                            </div>
                            <span className="mt-auto">{questionCounts[4] ?? 0}</span>
                        </div>
                        <div className="tile bg-lightGreen d-flex flex-column">
                            <div style={{ height: "48px" }} className="d-flex align-items-center justify-content-center">
                                <h5>5</h5>
                            </div>
                            <span className="mt-auto">{questionCounts[5] ?? 0}</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}