import { Modal, Button } from "react-bootstrap";
import { useState } from "react";

export function ConfirmDialog(props) {
    //const [showModal, setShowModal] = useState(false);
    const confirmButtonTitle = props.confirmButtonTitle ? props.confirmButtonTitle : "Yes";
    const cancelButtonTitle = props.cancelButtonTitle ? props.cancelButtonTitle : "No";
    const message = props.message ? props.message : "Are you sure?";

    const handleClose = () => props.setShowModal(false);

    return (
        <Modal
            show={props.showModal}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            { props.title &&
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
            }
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>{cancelButtonTitle}</Button>
                <Button variant="primary" onClick={props.handleConfirm}>{confirmButtonTitle}</Button>
            </Modal.Footer>
        </Modal>
    )
}