import { ListGroupItem } from "react-bootstrap";
import { useState } from "react";



export function ListGroupItemEllipsis(props) {
    const [ellipsisEnabled, setEllipsisEnabled] = useState(props.enabledByDefault);

    const toggleEllipsis = () => {
        setEllipsisEnabled(!ellipsisEnabled);
    }

    return (
        <ListGroupItem action onClick={toggleEllipsis}>
            <p className={`${ellipsisEnabled && "ellipsis "}align-middle mt-0 mb-0`}>{props.text}</p>
        </ListGroupItem>
    )
}