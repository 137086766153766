import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
    names: {
        signIn: 'B2C_1_alignorgsignin',
        //forgotPassword: 'B2C_1_reset_v3',
        //editProfile: 'B2C_1_edit_profile_v2',
    },
    authorities: {
        signIn: {
            authority: 'https://alignorg.b2clogin.com/alignorg.onmicrosoft.com/b2c_1_alignorgsignin',
            //authority: 'https://login.microsoftonline.com/398ae870-c71e-4a04-adac-13ebb16bf143',
        },
        // forgotPassword: {
        //     authority: 'https://alignorg.b2clogin.com/alignorg.onmicrosoft.com/B2C_1_reset_v3',
        // },
        // editProfile: {
        //     authority: 'https://alignorg.b2clogin.com/alignorg.onmicrosoft.com/b2c_1_edit_profile_v2',
        // },
    },
    authorityDomain: 'alignorg.b2clogin.com',
};

export const msalConfig = {
    auth: {
        clientId: '765bc502-1d50-47b4-bce1-a91806671d05', // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: process.env.REACT_APP_URL, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: process.env.REACT_APP_URL, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
    apiTodoList: {
        endpoint: 'http://localhost:5000/api/todolist',
        scopes: {
            read: ['https://fabrikamb2c.onmicrosoft.com/TodoList/ToDoList.Read'],
            write: ['https://fabrikamb2c.onmicrosoft.com/TodoList/ToDoList.ReadWrite'],
        },
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [...protectedResources.apiTodoList.scopes.read, ...protectedResources.apiTodoList.scopes.write],
};