import { Link } from 'react-router-dom';

export function DateCellRenderer (data) {
    return data.value ? (new Date(data.value)).toLocaleDateString() : ''; //.format('MM/DD/YYYY')
}

export function DateStringCellRenderer (data) {
    return data.value ? (new Date(data.value)).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''; //.format('MM/DD/YYYY')
}

export function LinkCellRenderer (params) {
    const clientId = params.data.id;
    return (
        <Link className="btn-primary p-0 ag-foreground-color text-decoration-none" to={`/Clients/${clientId}`}>{params.value}</Link>
        // <Button variant="link" className="p-0 text-decoration-none" onClick={params.onClick(clientId)}>{params.value}</Button>
    );
}