import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { SignInButton } from '../components/login/SignInButton';
import { SignOutButton } from '../components/login/SignOutButton';
import { useIsAuthenticated } from '@azure/msal-react';

export function NavBar() {
    const isAuthenticated = useIsAuthenticated();
    const location = useLocation();

    const [showNavBar, setShowNavBar] = useState(false);
    const [showSurveyResults, setshowSurveyResults] = useState(false);

    useEffect(() => {
      let path = location.pathname.toLowerCase();
        if(path.includes("/survey")) {
            setShowNavBar(false)
        } else {
            setShowNavBar(true)
        }
        if(path.includes("/clients/"))
          setshowSurveyResults(true);
    }, [location])

  return ( showNavBar &&
    <Navbar variant="light" expand="lg" className='shadow-sm py-1 mb-2'>
    {/* <Navbar variant="dark" bg="dark" expand="lg"> */}
      <Container fluid>
        <Navbar.Brand href="/">
          <img height="50" src="/alignorg-lockup-brand.png" />
        </Navbar.Brand>
        <Nav>
          {/* <Nav.Link href="/">Home</Nav.Link> */}
          {/* { showSurveyResults &&
            <Nav.Link href="/results">Survey Results</Nav.Link>
          } */}
          <Nav.Item>{isAuthenticated ? <SignOutButton /> : <SignInButton />}</Nav.Item>
        </Nav>
      </Container>
    </Navbar>
  )
}