import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { DateStringCellRenderer } from './grid/CellRenderers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleDot, faPencil, faX, faSave } from '@fortawesome/free-solid-svg-icons';

const ActionComponent = (props) => {
    if(props === undefined)
        return null;
    const data = props.data;
    if(data === undefined)
        return null;
    let editingCells = props.api.getEditingCells();
    let isCurrentRowEditing = editingCells.some((cell) => {
        return cell.rowIndex === props.node.rowIndex;
    });
    if(data.status === "Closed")
        return <Link className="btn btn-outline-primary" to={ `/results/${props.clientId}/${data.id}` } role="button">Survey Results</Link>
    if(isCurrentRowEditing) {
        return (
            <>
                <Button className="me-2" variant="primary" data-action="save"><FontAwesomeIcon pointerEvents={"none"} icon={faSave} /> Save</Button>
                <Button variant="secondary" data-action="cancel"><FontAwesomeIcon pointerEvents={"none"} icon={faX} /> Cancel</Button>
                {/* <Button variant="outline-primary" onClick={() => props.onClick(data)}>Close Survey</Button> */}
            </>
        );
    }
    return (
        <>
            <Button className="me-2" variant="primary" data-action="edit"><FontAwesomeIcon pointerEvents={"none"} icon={faPencil} /> Edit</Button>
            { props.canCloseSurvey && <Button variant="outline-danger" onClick={() => props.onClick(data)}><FontAwesomeIcon pointerEvents={"none"} icon={faX} /> Close Survey</Button> }
        </>
    );
}

const StatusComponent = (props) => {
    if(props === undefined)
        return null;
    const value = props.value;
    if(value === undefined)
        return null;
    if(value === "Closed")
        return <span><FontAwesomeIcon className="fg-green" icon={faCircleCheck} /> {value}</span>

    return <span><FontAwesomeIcon className="fg-blue" icon={faCircleDot} /> {value}</span>
}

const onCellClicked = (params) => {
    // Handle click event for action cells
    if (params.column.colId === "action" && params.event.target.dataset.action) {
        let action = params.event.target.dataset.action;
  
        if (action === "edit") {
          params.api.startEditingCell({
            rowIndex: params.node.rowIndex,
            // gets the first columnKey
            colKey: params.api.getDisplayedCenterColumns()[0].colId
          });
        }
  
        // if (action === "delete") {
        //   params.api.applyTransaction({
        //     remove: [params.node.data]
        //   });
        // }
  
        if (action === "save") {
          params.api.stopEditing(false);

        }
  
        if (action === "cancel") {
          params.api.stopEditing(true);
        }
      }
}

const onRowEditingStarted = (params) => {
    params.api.refreshCells({
        columns: ["action"],
        rowNodes: [params.node],
        force: true
    });
}

const onRowEditingStopped = (params) => {
    params.api.refreshCells({
        columns: ["action"],
        rowNodes: [params.node],
        force: true
    });
}

export function SurveyGrid(props) {

    const onRowValueChanged = (event) => {
        console.log(event.data);
        props.updateSurvey(event.data);
    }

    const columnDefs = [
        { 
            field: "startDate", 
            headerName: "Start Date", 
            cellDataType: 'dateString', 
            cellRenderer: DateStringCellRenderer, 
            editable: false
        },
        { 
            field: "endDate", 
            headerName: "End Date", 
            cellDataType: 'dateString', 
             cellRenderer: DateStringCellRenderer, 
            cellEditor: "agDateStringCellEditor",
            cellEditorParams: {
                min: "1900-01-01",
            }
        },
        { field: "status",  editable: false, cellRenderer: StatusComponent },
        { 
            headerName: "", 
            colId: "action",
            editable: false, 
            cellClass: "justify-content-center", 
            cellRenderer: ActionComponent, 
            cellRendererParams: { onClick: props.onClick, clientId: props.clientId, canCloseSurvey: props.canCloseSurvey } 
        }
    ];
    const defaultColDef = {
        editable: true,
        flex: 1
    }

    return (
        <div className="ag-theme-quartz flex-content-grow">
            <AgGridReact 
                rowData={props.rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowHeight={ 50 }
                editType="fullRow"
                suppressClickEdit={true}
                onCellClicked={onCellClicked}
                onRowEditingStarted={onRowEditingStarted}
                onRowEditingStopped={onRowEditingStopped}
                onRowValueChanged={onRowValueChanged}
                pagination={true}
                paginationAutoPageSize={true}
                style={{ width: "100%", height: "100%", minHeight: "205px"}}
            />
        </div>
    );
}